@import 'styles/_mixins.scss';
@import 'styles/_vars.scss';
@import 'styles/themes.scss';

.final-conditions {

  @include themify($themes) {
    font-family: themed('fontFamily');
  }

  &__header {
    text-align: center;
    .typography__section-header {
      margin-bottom: 30px;
      font-size: 24px;
    }
  }

  &__main {
    max-width: 487px;
    margin-top: 15px;
    background-color: #fff;
    border: 1px solid #B5B5B5;
    margin-right: 20px;
    &__blocks {
      display: flex;
      padding: 30px;
    }
    @include respond(tablet-portrait) {
      max-width: 100%;
      margin-right: 0;
      margin-bottom: 20px;
    }
  }



  &__content {
    display: flex;
    flex-direction: column;
    max-width: 315px;
    margin: 0 auto;

    & > * {
      width: 100%;
    }

    @include respond(tablet-portrait) {
      flex-direction: column;
      height: auto;
    }
  }

  &__main-left {
    width: 217px;
    .final-conditions-info-block__header {
      font-size: 12px;
    }
    @include respond(tablet-portrait) {
      width: 100%;
    }
  }

  &__main-right {
    width: 200px;

    @include respond(tablet-portrait) {
      width: 100%;
    }
  }

  &__side {
    background-color: #E4DCD3;
    padding: 30px;
    height: 100%;
    color: #05141f;

    .final-conditions-info-block__sum {
      font-size: 30px;
      font-weight: 700;
    }

    @include respond(tablet-portrait) {
      width: 100%;
      height: 160px;
    }
  }

  &__details {
    margin-bottom: 15px;
  }
}
