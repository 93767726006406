@import "src/styles/vars";
.card {
  border: 1px solid $color-grey;
  &__content {
    font-size: 12px;
    line-height: 15px;
  }
  &__title {
    padding: 15px;
    background-color: $color-lightgrey;
    border-bottom: 1px solid $color-grey;
    color: $color-blue;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
  }
  &.selected {
    border-color: $color-blue;
    .card__title {
      background-color: $color-blue;
      border-color: $color-blue;
      color: $color-white;
    }
  }
}
