.flex {
  display: flex;
  transition: opacity .3s;
}

.f1 {
  flex: 1;
}

.column {
  flex-direction: column;
}

.center {
  align-items: center;
  justify-content: center;
}

.jcsb {
  justify-content: space-between;
}

.jcse {
  justify-content: space-evenly;
}

.aic {
  align-items: center;
}

.ail {
  align-items: flex-start;
}

.w100 {
  width: 100%;
}

.half {
  width: 50%;
}

.third {
  width: 33.333333%;
}

.third2 {
  width: 66.666666%;
}

.quarter {
  width: 25%;
}

.stretch {
  justify-content: stretch;
}

.wrap {
  flex-wrap: wrap;
}

.relative {
  position: relative;
}
