@import 'styles/_mixins.scss';
@import 'styles/themes.scss';

.grid {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-column-gap: 10px;
  @include themify($themes) {
    grid-column-gap: themed('gridColumnGap');
  }
  // grid-row-gap: 16px;

  @for $i from 1 through 12 {
    &__col_width-#{$i} {
      grid-column: span #{$i};
    }
  }

  @include respond(tablet-portrait) {
    @for $i from 1 through 12 {
      &__col_width-#{$i} {
        grid-column: span 12;
      }
    }

    &__empty-cell {
      display: none;
    }
	}
}
