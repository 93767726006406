@import 'styles/_vars.scss';
@import 'styles/themes.scss';

.error {
  color: $color-error;
  font-size: 15px;
  font-weight: 700;
  margin: auto;
  margin-bottom: 24px;

  @include themify($themes) {
    color: themed('errorColor');
    font-style: themed('fieldErrorFontStyle');
    font-size: themed('fieldErrorFontSize');
  }
}
