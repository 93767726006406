@import 'styles/_vars.scss';

.container {
  width: 120px;
  &:not(:last-child) {
    margin-right: 9px;
  }
}

.label {
  font-size: 13px;
  margin-bottom: 6px
}

.wrapper {
  width: 84px;
  height: 32px;
  font-size: 15px;
  cursor: pointer;
  &:last-child, &:first-child {
    color: lightgrey;
  }
  > div.active {
    color: black;
  }
}

.icon {
  height: 15px;
  opacity: 1;
  transition: opacity .2s;
}

.switcher {
  width: 38px;
  height: 20px;
  border-radius: 20px;
  cursor: pointer;
  transition: background-color .2s;
  background-color: lightgrey;
}

.toggle {
  position: absolute;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  z-index: 10;
  box-shadow: 0 3px 3px rgba(black, .1);
  left: 0;
  background-color: green;
  transition: right .3s, left .3s;
}

.active .toggle {
  left: auto;
  right: 0;
}
