@import 'styles/themes.scss';

.agreement {
  width: 780px;
  padding: 40px;
  text-align: center;
  z-index: 1000;

  p  {
    text-align: justify;
    line-height: 1.5;
    margin-bottom: 8px;
  }

  button {
    height: 48px;
    margin: 24px auto 0;
    flex-shrink: 0;
    > div {
      flex: 1;
    }
  }

  table {
    margin: 20px 0;
    width: 100%;
  }

  table td, tr {
    border: 1px solid gray;
  }

  table td {
    padding: 5px;
    text-align: left;
  }

  @include themify($themes) {
    background-color: themed('popupBg');
    color: themed('popupTextColor');
    border: themed('popupBorder');
  }
}
