@import 'styles/_mixins.scss';
@import 'styles/_vars.scss';
@import 'styles/themes.scss';
.introduction {
  @include flexColumnAlignCenter;

  &__header.typography {
    font-size: 20px;
    @include themify($themes) {
      margin-bottom: themed('introductionHeaderMarginBottom');
      font-size: themed('introductionHeaderFontSize');
    }
  }

  &__subheading {
    margin-top: 5px;
    margin-bottom: 50px;
  }

  &__content {
    @include flexColumnAlignCenter;
    background-color: $block-background-color;
    padding: 40px 39px 40px 17px;
    width: 100%;
    margin-bottom: 60px;

    @include themify($themes) {
      background-color: themed('blockBg1');
    }
  }

  &__steps {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
  }
}
