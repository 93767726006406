@import 'styles/_vars.scss';
@import 'styles/_mixins.scss';
@import 'styles/themes.scss';

.faq {
  margin-top: 60px;
  margin-bottom: 25px;
  font-size: 18px;
  line-height: 1.4;

  &__questions-container {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: 1fr max-content;
    grid-gap: 15px;

    @include respond(tablet-portrait) {
      grid-template-columns: repeat(2, 1fr);
    }

    @include respond(mobile) {
      grid-template-columns: 1fr;
		}
  }

  &__question {
    position: relative;
    background-color: #f0f0f0;
    padding: 27px 36px;
    height: 190px;
    cursor: pointer;
    transition: box-shadow .3s;

    @include themify($themes) {
      background-color: themed(blockBg1);
    }

    &:hover {
      box-shadow: $shadow-mild;
    }

    &_active {
      font-weight: 500;
      @include themify($themes) {
        border: 1px solid themed(colorPrime);
      }

      .faq__question-triangle {
        display: block;
      }
    }
  }

  &__question-triangle {
    display: none;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 100%);
    border: 10px solid transparent;

    @include themify($themes) {
      border-top: 10px solid themed(colorPrime);
    }
  }

  &__answer {
    display: none;
    padding: 28px 35px;

    grid-column: 1 / -1;
    grid-row: 2 / -1;

    @include respond(tablet-portrait) {
      &:nth-child(n + 5) {
        grid-column: 1 / -1;
        grid-row: 3 / -1;
      }
    }

    @include respond(mobile) {
      grid-column: auto;
      grid-row: auto;

      &:nth-child(n + 5) {
        grid-column: auto;
        grid-row: auto;
      }
    }


    @include themify($themes) {
      border: 1px solid themed(colorPrime);
    }

    &_active {
      display: block;
    }
  }
}
