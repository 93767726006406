@import 'styles/_vars.scss';
@import 'styles/themes.scss';

.input-field {
  &__input-wrapper {
    display: flex;
    align-items: center;
    background-color: white;
    padding: 0 8px;
    height: $input-field-height;
    font-size: $input-field-font-size;
    font-weight: $input-field-font-weight;
    border: $input-field-border;
    width: 100%;

    &.disabled {
      pointer-events: none;
      background-color: $input-disabled-bg;
    }

    @include themify($themes) {
      border: themed('inputBorder');
      border-radius: themed('inputBorderRadius');
      font-size: themed('inputFontSize');
      font-weight: themed('inputFontWeight');
      padding: themed('inputPadding');
      height: themed('inputHeight');
      background-color: themed('inputBg');

      &_focus {
        border-color: themed('inputFocusBorderColor');
        box-shadow: themed('inputActiveBoxShadow');
      }

      &_error {
        border: 1px solid $color-error;
        border-color: themed('errorColor');
      }
    }
  }
  &__prefix {
    margin-right: 5px;
    padding-top: 8px;
    transform: translate(0, 3px);

    &_hidden {
      display: none;
    }

    &_disabled {
      color: inherit;
    }
  }

  &__postfix {
    margin-left: 5px;
    transform: translate(0, 3px);
  }

  &__input {
    display: block;
    width: 100%;
    padding-top:8px;
    transform: translate(0, 3px);
    font-size: 16px;
    &[disabled] {
      color: inherit;
    }
  }
}
