@import 'styles/_vars.scss';
@import 'styles/_mixins.scss';
@import 'styles/themes.scss';

.select-wrapper {
  position: relative;
  height: $input-field-height;
  width: 100%;
  cursor: pointer;
  background-color: white;
  border: $input-field-border;

  @include respond(tablet-portrait) {
    height: auto;
    min-height: $input-field-height;
    .select {
      width: 100%;
      padding: 0;
      font-size: $input-field-font-size;
      &__value {
        height: 47px;
        max-width: 90%;
        padding: 8px; } }
    .options {
      position: relative;
      top: 0;
      box-shadow: none;
      border-top: 1px solid #B5B5B5; }

    .option {
      padding: 15px 8px;
      font-size: 14px; } }

  &.select-v1 {
    height: 50px;
    border: $input-field-border; }

  &.mini {
    min-width: auto; }

  &_active {
    .icon {
      transform: translateY(-50%) rotate(180deg); }
    .select__value {
      @include respond(tablet-portrait) {
        //padding: 17px 8px 10px
        transform: translateY(5px); } } }

  @include themify($themes) {
    border: themed('formInputBorder');
    border-radius: themed('inputBorderRadius');
    background-color: themed('inputBg');
    height: themed('inputHeight');

    &.error {
      border: 1px solid $color-error;
      border-color: themed('errorColor'); }

    &_active {
      border: themed('inputFocusBorder') !important; } } }


.with_border {
  @include themify($themes) {
    border: themed('inputBorder'); } }

.label {
  font-size: 13px;
  color: $color-black;
  pointer-events: none;
  margin-bottom: 6px; }

.select {
  display: flex;
  align-items: center;
  width: 93%;
  height: 100%;
  font-size: $input-field-font-size;
  font-weight: $input-field-font-weight;
  padding: 8px;
  @include themify($themes) {
    font-size: themed('selectFontSize');
    font-weight: themed('selectFontWeight'); }

  @include respond(tablet-portrait) {
    display: block; } }

.options {
  position: absolute;
  top: $input-field-height;
  left: 0;
  width: 100%;
  max-height: 240px;
  background-color: white;
  box-shadow: 0 3px 8px rgba(black, .3);
  z-index: 101;
  overflow-y: auto;

  @include themify($themes) {
    border: themed('inputFocusBorder');
    background-color: themed('inputBg');
    background-color: themed('selectBg'); } }

.option {
  margin-right: 0 !important;
  padding: 7.5px 15px;
  font-style: normal;
  color: black;
  font-size: 12px;
  &:hover {
    background-color: rgba(lightgrey, .5);
    @include themify($themes) {
      background-color: themed('selectSelectionColor'); } }

  @include themify($themes) {
    color: themed('selectFontColor'); } }

.icon {
  position: absolute;
  width: 20px;
  height: 20px;
  top: 50%;
  right: 10px;
  transform: translateY(-50%); }

.select-backdrop {
  background-color: transparent; }

.field {
  .select__value {
    transform: translateY(8px);
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    color: black;
    font-style: normal;
    line-height: 1.2;
    font-size: 16px;
    @include themify($themes) {
      color: themed('selectFontColor'); }

    @include respond(tablet-portrait) {
      transform: translateY(14px); } } }

