@import 'styles/_vars.scss';
@import 'styles/_mixins.scss';
@import 'styles/themes.scss';

.questionary-tabs {
  height: 48px;
  @include themify($themes) {
    margin: themed('tabMargin');
    height: themed('tabHeight');
  }
  :not(:last-child) {
    border-right: $form-border;
    @include themify($themes) {
      border-right: themed('tabBorderRight');
    }
  }
  @include respond(tablet-portrait) {
    margin: 0 auto !important;
    height: 30px !important;
    max-width: 94%;
    border-top: 1px solid rgba(168, 168, 168, 0.5);
    border-right: 1px solid rgba(168, 168, 168, 0.5);
    border-left: 1px solid rgba(168, 168, 168, 0.5);
    & + * {
      border-top: 1px solid rgba(168, 168, 168, 0.5);
    }
  }

  &__tab {
    display: flex;
    align-items: center;
    justify-content: center;
    width: calc(100% / 3);
    height: 100%;
    color: $color-grey;
    text-align: center;
    border-bottom: $form-border;
    @include respond(mobile-landscape) {
      &:not(&_active) {
        display: none;
      }
      &_active {
        width: 100%;
      }
    }

    @include themify($themes) {
      background-color: themed('tabBackgroundColor');
      border-bottom: themed('tabBorderBottom');
      color: themed('tabTextColor');
    }

    @include respond(tablet-portrait) {
      font-size: 12px;
    }

    &_active {
      color: black;
      background-color: #E4DCD3;
      @include themify($themes) {
        background-color: themed('tabActiveBackgroundColor');
        color: themed('tabActiveTextColor');
      }
    }

    &_hidden {
      display: none;
    }
  }
}
