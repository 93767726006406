@import 'styles/_vars.scss';
@import 'styles/themes.scss';

.error {
  color: $color-error;
  font-size: 10px;
  line-height: 11px;
  transition: opacity 300ms;
  min-height: 12px;
  font-style: italic;

  @include themify($themes) {
    color: themed('errorColor');
    font-style: themed('fieldErrorFontStyle');
    font-size: themed('fieldErrorFontSize');
  }
}

.hide {
  display: none;
}
