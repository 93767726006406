@import "styles/_mixins.scss";
@import "styles/_vars.scss";
@import 'styles/themes.scss';

.sms-confirmation-modal {
  @include respond(tablet-portrait) {
    width: 100vh;
    height: 100vh;
    max-height: 100vh;
  }

  @include themify($themes) {
    background-color: themed('popupBg');
    color: themed('popupTextColor');
    border: themed('popupBorder');
  }
}
.sms-confirmation {
  height: 350px;
  max-width: 314px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  @include respond(tablet-portrait) {
    height: calc(100vh - 100px);
  }
  &__text {
    max-width: 250px;
    margin-bottom: 30px;
    font-size: 14px;
    font-weight: 500;
  }
  &__bottom {
    margin-top: auto;
  }
  small {
    display: block;
    line-height: 14px;
    text-align: center;
  }
  &__timer {
    margin-bottom: 15px;
    text-align: center;
    font-size: 12px;
    color: $color-grey;
  }
  &__submit {
    width: 100%;
    margin-top: 40px;
  }
}
