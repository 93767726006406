@import 'styles/_vars.scss';
@import 'styles/_mixins.scss';
@import 'styles/themes.scss';

.alternative-input-field {
  &__input-wrapper {
    display: flex;
    align-items: center;
    background-color: white;
    padding: 0px 8px;
    height: 42px;
    font-size: 15px;
    border: 1px solid transparent;
    line-height: 18px;

    @include themify($themes) {
      border: themed('inputBorder');
      border-radius: themed('inputBorderRadius');
      font-size: themed('inputFontSize');
      font-weight: themed('inputFontWeight');
      padding: themed('inputPadding');
      height: themed('inputHeight');
      background-color: themed('inputBg');

      &_focus {
        border-color: themed('inputFocusBorderColor');
        box-shadow: themed('inputActiveBoxShadow');
      }

      &_error {
        border: 1px solid $color-error;
        border-color: themed('errorColor');
      }
    }
  }
  &__prefix {
    margin-right: 5px;
  }

  &__postfix {
    margin-left: 5px;
  }

  &__input {
    display: block;
    width: 100%;
  }
}
