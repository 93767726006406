@import 'styles/themes.scss';
@import 'styles/_mixins.scss';

.introduction-step {
  display: flex;
  font-weight: 500;
  width: 30%;
  align-items: center;
  margin: 0 10px;

  @include respond(tablet-portrait) {
    width: 50%;
    &:not(:last-child) {
      padding-bottom: 30px;
    }
  }

  @include respond(mobile-landscape) {
    width: 100%;
  }

  &__number {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 15px;
    font-size: 30px;

    @include themify($themes) {
      display: themed('introductionNumberDisplay');
      width: themed('introductionNumberWidth');
      height: themed('introductionNumberHeight');
      background-color: themed('introductionNumberBackgroundColor');
      border-radius: themed('introductionNumberBorderRadius');
      color: themed('colorPrime');
      color: themed('introductionFontColorStep');
      font-weight: themed('introductionFontWeight');
    }
  }

  &__text {
    font-size: 16px;

    @include themify($themes) {
      font-size: themed('introductionFontSize');
      max-width: themed('introductionTextMaxWidth');
      font-weight: themed('introductionFontWeight');
      @include respond(mobile-landscape) {
        max-width: 160px;
      }
    }
  }
}
