@import 'styles/_vars.scss';
@import 'styles/themes.scss';

.group-container {
  label {
    display: flex;
    align-items: center;
    cursor: pointer; }

  .radio {
    width: 1px;
    height: 1px;
    opacity: 0; }

  .input_pseudo {
    width: 16px;
    height: 16px;
    border: 1px solid lightgrey;
    border-radius: 50%;

    @include themify($themes) {
      border: 2px solid themed(inputBorderColor); } }

  .radio:focus + .input_pseudo {
    @include themify($themes) {
      border: 2px solid themed(colorPrime); } }


  .dot {
    width: 8px;
    height: 8px;
    @include themify($themes) {
      background-color: themed(colorPrime); }
    border-radius: 50%; }

  .radio__label {
    margin-left: 10px;
    margin-right: 40px;

    display: flex;
    align-items: center;
    white-space: nowrap;
    font-size: 13px;
    color: $color-black;
    height: 14px;

    @include themify($themes) {
      text-transform: themed('fieldLabelTextTransform');
      font-weight: themed('labelFontWeight');
      font-size: themed('fieldLabelFontSize');
      font-family: themed('fieldLabelFontFamily');
      color: themed('inputFontColor'); } } }
