@import 'styles/_vars.scss';
@import 'styles/_mixins.scss';
@import 'styles/themes.scss';

.steps-header {
  margin-bottom: 16px;
  @include themify($themes) {
    margin-bottom: themed('stepsHeaderMarginBottom'); }
  .container-subcaption {
    color: black;
    font-size: 16px;
    transform: translateY(-16px); } }

.tabs {
  height: 48px;
  .tab {
    display: flex;
    align-items: center;
    justify-content: center;
    width: calc(100% / 3);
    height: 100%;
    color: $color-grey;
    text-align: center;
    &.active {
      @include themify($themes) {
        background-color: themed('colorPrime'); }
      color: white;
      font-weight: 500; } } }

.withBorder, .part section {
  border-bottom: 1px solid lightgrey;
  margin-bottom: 24px; }

.part-short {
  width: 600px;
  max-width: 100%;
  padding-bottom: 40px;

  @media (max-width: $tablet-landscape) {
    width: auto; }

  &__controls {
    .flex {
      align-items: center; }

    .button-v1 {
      margin-top: 12px;
      height: 55px;
      width: 250px;
      background: none;
      font-size: 18px;
      @include themify($themes) {
        color: themed('colorPrime');
        border: 1px solid themed('colorPrime');
        background-color: themed('infoShortButtonBackgroundColor'); } } }

  .checkbox-container {

    .label {
      font-size: 17px; }

    .checkbox {
      width: 24px;
      height: 24px;
      margin-top: -5px; } }

  .field-container {
    margin-right: 100px;
    &:last-child {
      margin-right: 0; }

    @include media($iphone) {
      margin-right: 0; } } }

.part {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 60px 92px 24px;
  @include media($iphone) {
    padding: 26px 10px; }
  section {
    display: flex;
    flex-direction: column;
    padding-bottom: 16px;
    width: 100%; }
  .block_caption {
    font-size: 25px;
    font-weight: 500;
    color: $color-black;
    margin-bottom: 32px;
    @include media($iphone) {
      text-align: center;
      font-size: 20px; }
    @include themify($themes) {
      font-weight: themed('blockCaptionFontWeight');
      color: themed('blockCaptionColor'); } }
  button {
    margin: auto; } }

.warning {
  @include themify($themes) {
    color: themed('colorPrime'); }
  font-size: 15px;
  font-weight: 700;
  margin: auto;
  margin-bottom: 24px; }

%unavailable {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  color: $color-grey;
  font-size: 18px;
  font-weight: 700;
  z-index: 100; }

.unavailable {
  @extend %unavailable; }

.unavailable_colored {
  @extend %unavailable;
  background-color: rgba(white, .9); }

.sms_code {
  margin-top: 20px; }
.timer {
  color: lightgrey;
  transform: translate(10px); }

.btns {
  margin: auto;
  margin-left: 0;
  @include respond(tablet-portrait) {
    width: 100%; } }

.blue {
  color: $color-blue;
  font-weight: 700; }

.info {
  &__mobile-phone {
    &_disabled {
      opacity: .8; } }

  &__repeat-sms-code-button {
    text-align: center;
    margin: 0 auto; } }

.app-form {
  &__form {
    width: 100%;
    border: $form-border;
    background-color: transparent;
    @include themify($themes) {
      border: themed('formBorder');
      background-color: themed('blockBg1'); }

    @include respond(tablet-portrait) {
      margin-top: -15px;
      padding-top: 15px;
      border: 0; } }
  &__dealer-select {
    width: 282px; } }

