@import 'styles/_vars.scss';
@import 'styles/themes.scss';

.dadata-wrapper {
  display: flex;
  flex-direction: column;
  position: relative;

  input {
    height: 42px;
    line-height: 42px;
    border-radius: 0;
    background-color: white;

    @include themify($themes) {
      border: themed('formInputBorder');
      border-radius: themed('inputBorderRadius');
      height: themed('inputHeight');
      line-height: themed('inputHeight');
      background-color: themed('inputBg');

      &:focus {
        border-color: themed('inputFocusBorderColor');
        box-shadow: themed('inputActiveBoxShadow');
      }
    }
  }

  &_error {
    input {
      @include themify($themes) {
        border: 1px solid $color-error;
        border-color: themed('errorColor');
      }
    }
  }
}
.label {
  font-size: 13px;
  color: $color-black;
  pointer-events: none;
  margin-bottom: 6px;

  @include themify($themes) {
    font-weight: themed('labelFontWeight');
  }
}
