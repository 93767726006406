@import 'styles/themes.scss';

.info {
  &__phone-confirmed-text {
    margin: 15px 0 5px 0;
    font-weight: 500;

    @include themify($themes) {
      color: themed('colorPrime');
    }
  }

  &__agreement {
    align-items: flex-start;
    margin-bottom: 35px;
    height: auto;
  }

  &__agreement-item {
    text-decoration-line: underline;
    text-decoration-style: dashed;
  }

  &__agreement_caption {
    margin-bottom: 10px;
  }

  &__contacts {
    margin-top: 40px;
  }
}

[disabled] {
  color: gray;
}
