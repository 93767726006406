@import 'src/styles/_mixins.scss';

.carousel-button {
  width: 20px;
  height: 28px;
  position: absolute;
  top: 50%;
  cursor: pointer;
  transition: opacity .1s;
  z-index: 10;
  @include respond(tablet-portrait) {
    position: absolute;
    margin: 127px -55px 0;
  }

  &__next {
    right: -40px;
    transform: translateY(-50%);

    @include respond(tablet-portrait) {
      right: 50%;
      transform: translate(-50%, 0);
      margin-right: -70px;
    }
  }

  &__previous {
    left: -40px;
    transform: rotate(180deg);

    &_disabled {
      //left: -25px;
    }

    @include respond(tablet-portrait) {
      left: 50%;
      transform: translate(-50%, 0) rotate(180deg);
    }
  }
}
