@import 'styles/_vars.scss';


.icon {
  font-family: 'ecr_icons' !important;
  font-size: 24px;
  color: black;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color .3s, color .4s; }

.minilogo {
  background: url('../../../assets/e.svg') no-repeat center;
  background-size: 50%;
  background-color: black; }

.select {
  background: url('../../../assets/select.svg') no-repeat center;
  background-size: 50%; }

.arrow {
  background: url('../../../assets/arrow.svg') no-repeat center;
  background-size: 100%; }

.check {
  background: url('../../../assets/checkbox.svg') no-repeat center;
  background-size: 100%; }

.filter:before {
  content: "\e916"; }
.printer:before {
  content: "\e915"; }
.close:before {
  content: "\e914"; }
.restore:before {
  content: "\e905"; }
.add_fill:before {
  content: "\e912"; }
.close_circle:before {
  content: "\e913"; }
.search_2:before {
  content: "\e910"; }
.search:before {
  content: "\e900"; }
.check:before {
  content: "\e901"; }
.add:before {
  content: "\e902"; }
.play:before {
  content: "\e903"; }
.copy:before {
  content: "\e904"; }
.chevron_r:before {
  content: "\e911"; }
.bank:before {
  content: "\e906"; }
.download:before {
  content: "\e907"; }
.news:before {
  content: "\e908"; }
.info:before {
  content: "\e90f"; }
.chart:before {
  content: "\e909"; }
.calendar:before {
  content: "\e90a"; }
.cart:before {
  content: "\e90b"; }
.settings:before {
  content: "\e90c"; }
.user:before {
  content: "\e90d"; }
.exit:before {
  content: "\e90e"; }
