@import 'styles/_vars.scss';
@import 'styles/themes.scss';

.redux-form-input-field {
  &.input-field__input-wrapper, &.alternative-input-field__input-wrapper {
    @include themify($themes) {
      border: themed('formInputBorder');
      background-color: themed('inputBg');

      &_error {
        border: 1px solid $color-error;
        border-color: themed('errorColor');
      }

      &_focus {
        border-color: themed('inputFocusBorderColor');
        box-shadow: themed('inputActiveBoxShadow');
      }
    }
  }
}
