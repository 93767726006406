@import 'styles/themes.scss';
@import 'styles/_mixins.scss';
@import 'styles/_vars.scss';

.drivers-field {
  &__add-button {
    justify-content: flex-start;
    align-self: flex-start;
    min-width: 40px;

    @include respond(tablet-portrait) {
      margin: 0 auto 60px;
      align-self: center;
      justify-content: center;
    }

    & svg {
      @include themify($themes) {
        fill: themed('colorPrime');
      }
    }
  }

  &__add-button-label {
    padding-left: 10px;
  }
}
