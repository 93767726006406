@import 'styles/_mixins.scss';
@import 'styles/_vars.scss';
.driver {
  margin-bottom: 60px;

  &__remove-button {
    min-width: 40px;
    cursor: pointer;

    &.button_flat {
      margin-left: 90px;
      height: 15px;
      @include respond(tablet-portrait) {
        margin: 0;
        width: auto;
        svg {
          width: 20px;
          height: 20px;
        }
      }
    }
  }

  &__header {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
    h3 {
      margin-bottom: 0;
    }
    @include respond(tablet-portrait) {
      justify-content: space-between;
    }
  }
}
