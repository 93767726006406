@import 'vars';


@mixin media($width) {
  @media only screen and (max-width: $width) {
    @content;
  }
}

@mixin flexColumnAlignCenter {
  display: flex;
  flex-direction: column;
  align-items: center;
}


@mixin respond($breakpoint) {
  @if $breakpoint == mobile {
      @media only screen and (max-width: $mobile) { @content };
  }
  @if $breakpoint == mobile-landscape {
      @media only screen and (max-width: $mobile-landscape) { @content };
  }
  @if $breakpoint == tablet-portrait {
      @media only screen and (max-width: $tablet-portrait) { @content };
  }
  @if $breakpoint == tablet-landscape {
    @media only screen and (max-width: $tablet-landscape) { @content };
  }
  @if $breakpoint == desktop {
      @media only screen and (min-width: $desktop) { @content };
  }
}
