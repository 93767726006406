@import 'styles/_vars.scss';
@import 'styles/_mixins.scss';

.container {
  width: 100vw;
  max-width: 1024px;
  height: 100%;
  min-height: 100%;
  margin: 0 auto;
  padding: 20px 0;
  @include media($iphone) {
 } }    //padding: 50px 0
