@import 'styles/_mixins.scss';
@import 'styles/_vars.scss';
@import 'styles/themes.scss';

.field {
  display: flex;
  flex-direction: column;
  position: relative;
  margin-bottom: 16px;

  &__label {
    position: absolute;
    transform-origin: top left;
    transform: translate(0, 16px) scale(1);
    left: 9px;
    transition: all .1s ease-in-out;

    display: flex;
    align-items: flex-end;
    white-space: nowrap;
    z-index: 2;
    font-size: 14px;
    color: $label-color;

    @include themify($themes) {
      &_active {
        transform: translate(0, 7px) scale(.90);
        font-size: 12px;
        font-weight: 400;
      }

      transform: themed('fieldLabelTransform');
      text-transform: themed('fieldLabelTextTransform');
      font-weight: themed('labelFontWeight');
      font-size: themed('fieldLabelFontSize');
      font-family: themed('fieldLabelFontFamily');
      margin-bottom: themed('fieldLabelMarginBottom');
      color: themed('fieldLabelColor');
    }
  }

  &__content {
    display: flex;
    align-items: center;

    :not(:last-child) {
      margin-right: 5px;
    }
  }

  &__error {
    margin-top: 4px;
    color: #DD0A0A;
    font-size: 10px;
    line-height: 11px;
    min-height: 12px;
    font-style: italic;

    @include themify($themes) {
      color: themed('errorColor');
      font-style: themed('fieldErrorFontStyle');
      font-size: themed('fieldErrorFontSize');
    }
  }

  &__required-star {
    margin-right: 10px;
  }
}
