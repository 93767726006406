@font-face {
  font-family: 'ecr_icons';
  src:
    url('../assets/fonts/ecr_icons.ttf?q66yd') format('truetype'),
    url('../assets/fonts/ecr_icons.woff?q66yd') format('woff'),
    url('../assets/fonts/ecr_icons.svg?q66yd#ecr_icons') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Mazda Type Cyrillic";
  src: url("../assets/fonts/mazda/MazdaTypeCyrillic-Regular.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Mazda Type Cyrillic";
  src: url("../assets/fonts/mazda/MazdaTypeCyrillic-Bold.otf") format("opentype");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "Mazda Type Cyrillic";
  src: url("../assets/fonts/mazda/MazdaTypeCyrillic-Medium.otf") format("opentype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "SuzukiPROHeadline";
  src: url("../assets/fonts/suzuki/SuzukiPROHeadline.otf") format("opentype");
  font-style: normal;
}

@font-face {
  font-family: "SuzukiPRO";
  src: url("../assets/fonts/suzuki/SuzukiPRORegular.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "SuzukiPRO";
  src: url("../assets/fonts/suzuki/SuzukiPROBold.otf") format("opentype");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "HyundaiSansText";
  src: url("../assets/fonts/hyundai/HyundaiSansText-Regular.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "HyundaiSansText";
  src: url("../assets/fonts/hyundai/HyundaiSansText-Medium.otf") format("opentype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "HyundaiSansText";
  src: url("../assets/fonts/hyundai/HyundaiSansText-MediumItalic.otf") format("opentype");
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: "HyundaiSansText";
  src: url("../assets/fonts/hyundai/HyundaiSansText-Italic.otf") format("opentype");
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: "HyundaiSansText";
  src: url("../assets/fonts/hyundai/HyundaiSansText-Bold.otf") format("opentype");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "HyundaiSansText";
  src: url("../assets/fonts/hyundai/HyundaiSansText-BoldItalic.otf") format("opentype");
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: "HyundaiSansHead";
  src: url("../assets/fonts/hyundai/HyundaiSansHead-Regular.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "HyundaiSansHead";
  src: url("../assets/fonts/hyundai/HyundaiSansHead-Medium.otf") format("opentype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "HyundaiSansHead";
  src: url("../assets/fonts/hyundai/HyundaiSansHead-Bold.otf") format("opentype");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "MMC";
  src: url("../assets/fonts/mitsubishi/MMC-Regular.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "MMC";
  src: url("../assets/fonts/mitsubishi/MMC-Medium.otf") format("opentype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "MMC";
  src: url("../assets/fonts/mitsubishi/MMC-Bold.otf") format("opentype");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "KIA";
  src: url("../assets/fonts/kia/KiaMedium.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "KIA";
  src: url("../assets/fonts/kia/KiaLight.otf") format("opentype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "KIA";
  src: url("../assets/fonts/kia/KiaBold.otf") format("opentype");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "Oswald";
  src: url("../assets/fonts/autodrom/Oswald-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "Oswald";
  src: url("../assets/fonts/autodrom/Oswald-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Oswald";
  src: url("../assets/fonts/autodrom/Oswald-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Play";
  src: url("../assets/fonts/autodrom/Play-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Exeed Header";
  src: url("../assets/fonts/exeed/TacticSansExd-Bld.ttf") format("truetype");
}

@font-face {
  font-family: "Exeed";
  src: url("../assets/fonts/exeed/NunitoSans-Regular.ttf") format("truetype");
}
