@import 'src/styles/_vars.scss';
@import 'src/styles/_mixins.scss';
@import 'src/styles/themes.scss';

.offers {
  @include media($tablet-landscape) {
 }    //padding: 0 25px

  .offer {
    align-items: center; }

  &__last-payment {
    color: gray;
    font-size: 17px; }

  &__blocked {
    opacity: 0.5;
    pointer-events: none; } }

.offers-container {
  display: flex;
  margin-bottom: 20px;
  padding-top: 12px;
  > div {
    flex: 1; }
  @include media($iphone) {
    margin-bottom: 8px; }

  &--empty {
    overflow: hidden;
    .offer {
      margin: 0 5px;
      @include media($tablet-landscape) {
        margin: 0 10px; } } }
  .no_offers {
    background-color: rgba($color-grey, .1);
    height: 240px;
    color: $color-grey;
    font-size: 18px;
    font-weight: 700;

    @include themify($themes) {
      background-color: themed('blockBg1'); } }

  .offer-year {
    width: 247px;
    height: 249px;
    overflow: hidden;
    border: 1px solid $color-lightgrey;
    transition: transform .3s;

    @include themify($themes) {
      border: themed('offerBorder');
      background-color: themed('blockBg1');
      background-color: themed('offerBg'); }

    @include media($tablet-landscape) {
      width: 160px;
      height: 210px; }

    &:not(.active):not(.placeholder):hover {
      transform: translateY(-12px);
      cursor: pointer; }
    &.active {
      @include themify($themes) {
        border: 1px solid themed('colorPrime'); }
      transform: translateY(-12px);
      .header {
        @include themify($themes) {
          background-color: themed('colorPrime'); }
        color: white; } } }

  .offer-dots {
    > div {
      width: 5px;
      height: 5px;
      background-color: #a8a8a8;
      border-radius: 50%;
      &:not(:last-child) {
        margin-right: 16px; }
      &.active {
        @include themify($themes) {
          background-color: themed('colorPrime'); } } } }

  .offer-header {
    height: 38px;
    background-color: $color-lightgrey;
    text-transform: uppercase;
    color: $color-grey;
    font-weight: 500;

    @include themify($themes) {
      background-color: themed('blockBg2');
      background-color: themed('offerHeaderBg');
      color: themed('offerHeaderColor'); }

    &_active {
      color: white;
      @include themify($themes) {
        background-color: themed('colorPrime');
        background-color: themed('offerHeaderBackgroundColor'); } } }

  .offer-body {
    height: 240px;
    h4 {
      color: rgba($color-grey, .4);
      font-size: 17px;
      font-weight: 500;
      margin-bottom: 18px;
      @include themify($themes) {
        color: themed('offerDescriptionColor'); }
      @include media($tablet-landscape) {
        font-size: 14px;
        font-weight: 500; } } }

  .offer-price {
    @include themify($themes) {
      color: themed('offerPriceColor'); }
    font-weight: 700;
    font-size: 50px;
    @include media($tablet-landscape) {
      font-size: 32px;
      font-weight: 500; } }
  .hr {
    height: 1px;
    width: 100px;
    background-color: $color-lightgrey;
    margin: 20px auto;
    @include themify($themes) {
      background-color: themed('offerLineColor'); } }

  .offer-percent {
    font-size: 17px;
    transform: translateY(-10px);
    @include media($tablet-landscape) {
      font-size: 14px; } }

  .plus {
    position: absolute;
    right: 8px;
    color: #00ABDE;
    transform: rotate(45deg);
    &_active {
      transform: rotate(0); }

    path {
      @include themify($themes) {
        fill: themed('offerPlusBg');
        stroke: themed('offerPlusBorderColor'); } } }

  .left, .right {
    width: 20px;
    height: 28px;
    position: absolute;
    top: 50%;
    cursor: pointer;
    transition: opacity .1s; }

  .left {
    left: 0;
    transform: translate(-48px, -50%) rotate(180deg); }

  .right {
    right: 0;
    transform: translate(48px, -50%); }

  .unable {
    opacity: .1; } }
