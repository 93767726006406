@import 'styles/_vars.scss';
@import 'styles/_mixins.scss';
@import 'styles/themes.scss';

.button {
  display: flex;
  justify-content: center;
  align-items: center;

  font-size: 13px;
  font-weight: 500;
  cursor: pointer;
  transition: all .3s;
  height: 50px;
  padding: 0 16px;
  min-width: 208px;
  @include respond(tablet-portrait) {
    width: 100%;
  }

  @include themify($themes) {
    color: themed('buttonTextColor');
    background-color: themed('buttonColor');
    text-transform: themed('buttonTextTransform');
    border: themed('buttonBorder');
    border-radius: themed('buttonBorderRadius');
    font-size: themed('buttonFontSize');
    height: themed('buttonHeight');
    min-width: themed('buttonMinWidth');

    &:hover {
      background-color: themed('buttonHoverColor');
      border: themed('buttonHoverBorder');
      box-shadow: themed('buttonHoverShadow');
      color: themed('buttonHoverTextColor');
    }
  };

  &_disabled {
    @include themify($themes) {
      background-color: themed('buttonDisabledColor');
      color: themed('buttonDisabledTextColor');
      border: themed('buttonDisabledBorder');
      opacity: themed('buttonDisabledOpacity');
    }

    cursor: default;
    pointer-events: none;
    &:hover {
      box-shadow: none;
    }
  }

  &_flat {
    @include themify($themes) {
      background-color: transparent;
      color: themed('flatButtonColor');
      font-size: 13px;
      text-decoration: underline;
      height: unset;
      padding: 0;
      text-transform: none;
      border: none;

      &:hover.button:hover {
        background-color: transparent;
        box-shadow: none;
        color: themed('flatButtonColor');
        border: none;
      }
    };
  }
}
