@import "src/styles/mixins";
.equipment-selector {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 90px;
  @include respond(tablet-landscape) {
    flex-direction: column;
    margin-bottom: 0;
  }
}
