@import 'styles/_mixins.scss';
@import 'styles/_vars.scss';
@import 'styles/themes.scss';

.final-conditions-info-block {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  &__header {
    font-size: 14px;
    margin-bottom: auto;
    text-align: center;
  }

  &__info {
    margin-top: 10px;
    font-size: 16px;
    font-weight: 500;

    @include respond(mobile-landscape) {
      font-size: 25px;
    }
  }

  &__units {
    font-size: 16px;
  }

  &__plus {
    margin-left: -25px;
    position: absolute;
    z-index: 2;
  }

  &__details {
    text-decoration: underline;
    font-size: 12px;
    color: #808080;
    cursor: pointer;
    position: relative;

    &_hidden {
      visibility: hidden;
    }
  }

  &__details-content {
    position: absolute;
    left: 0;
    top: 15px;
    z-index: 10;
  }
}
