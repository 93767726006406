@import 'styles/_vars.scss';
@import 'styles/_mixins.scss';
@import 'styles/themes.scss';

.partners {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $block-background-color;
  width: 100%;
  padding: 25px 60px;

  @include themify($themes) {
    background-color: themed('blockBg1');
  }

  &__image {
    object-fit: scale-down;
    width: 130px;
    margin: 0 15px;

    @include respond(tablet-portrait) {
      margin: 0 5px;
    }

    @include respond(mobile-landscape) {
      width: 100px;
    }

    @include respond(mobile) {
      width: 70px;
    }
  }

  &__image_highlighted {
    width: 155px;
    margin-right: 60px;

    @include respond(tablet-portrait) {
      margin: 30px;
    }

    @include respond(mobile-landscape) {
      width: 130px;
      margin: 5px;
    }
  }

  &__image_lowlighted {
    width: 70px;

    @include respond(mobile-landscape) {
      width: 50px;
    }
  }

  &__container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;

    @include respond(tablet-portrait) {
      justify-content: space-around;
    }
  }

  @include respond(tablet-portrait) {
    flex-wrap: wrap;
  }

  @include respond(mobile-landscape) {
    padding: 25px 0;
  }

  &__heading {
    min-width: 130px;
    @include respond(tablet-portrait) {
      width: 100%;
      text-align: center;
      margin-bottom: 25px;
    }
  }
}
