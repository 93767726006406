// default colors
$color-second: #00a6b7;

$color-white: white;
$color-red: red;
$color-black: black;
$color-grey: #808080;
$color-light: #f9f9f9;
$color-dark: #121212;
$color-table-header: #f0f0f0;
$color-lightgrey: rgb(238, 238, 238);
$color-blue: #002e6b;
$color-lightblue: #00ABDE;

$color-text-grey: #e0e0e0;
$color-modal-bg: #f4f4f4;

$color-aside: #28373e;
$color-link: #afb7c3;

$block-background-color: #F6F3F2;

$label-color: #B5B5B5;
$input-field-height: 49px;
$input-field-font-size: 14px;
$input-field-font-weight: 500;
$input-field-border: 1px solid #B5B5B5;
$input-disabled-bg: #F7F7F7;

$form-border: 1px solid rgba(168, 168, 168, 0.5);

$zindex-aside: 100;
$font: Roboto, sans-serif, -apple-system, "Helvetica Neue";
$shadow-mild: 0 3px 5px rgba($color-grey, 0.4);
$shadow-aside: 5px 0 5px rgba($color-grey, 0.2);

$color-error: #DD0A0A;
$footer-background-color: #B5B5B5;
$form-input-border-color: #F6F3F2;
$mobile: 414px;
$mobile-landscape: 568px;
$iphone: 720px;
$tablet-portrait: 768px;
$tablet-landscape: 1024px;
$desktop: 1200px;

$font-size-main-header: 36px;
$font-size-main-header-iphone: 25px;

$font-style-italic: italic;
