@import "../styles.sass";
@import 'src/styles/themes.scss';
@import 'src/styles/mixins.scss';
.info-short {
  @include themify($themes) {
    width: themed('infoShortWidth');
    background-color: themed('infoShortBackgroundColor');
  }
  &__agreement {
    align-items: flex-start;
    height: auto;
    margin-bottom: 25px;
  }

  &__agreement-item {
    text-decoration-line: underline;
    text-decoration-style: dashed;
  }

  &__agreement_check_block .error {
    margin-bottom: 25px;
    width: 100%;
  }
  &__agreement-text {
    margin-bottom: 25px;
    font-size: 10px;
  }
  &__steps {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 60px;
    @include themify($themes) {
      padding: themed('infoShortStepsPadding');
    }
    @include respond(tablet-landscape) {
      display: block;
    }
    &__step {
      display: flex;
      align-items: center;
      justify-content: center;
      max-width: 175px;

      &:not(:last-child) {
        margin-right: 30px;
      }
      @include respond(tablet-landscape) {
        width: 100%;
        max-width: 100%;
        padding: 0 30px;
        justify-content: flex-start;
        text-align: left;
        &:not(:last-child) {
          margin-right: 0;
        }
      }
      svg {
        min-width: 35px;
        @include respond(tablet-landscape) {
          margin: 10px 0;
        }
      }
      span {
        margin-left: 7px;
        font-weight: 500;
        @include respond(tablet-landscape) {
          margin-left: 15px;
          font-weight: 500;
          font-size: 14px;
        }
      }
      &--arrow {
        svg {
          @include respond(tablet-landscape) {
            transform: rotate(90deg);
            margin-left: -5px;
          }
        }
      }
    }
  }
}

.part-short {
  padding: 0 30px;
  &__controls {
    max-width: 571px;
    margin: 15px auto 0;
  }
  @include respond(desktop) {
    @include themify($themes) {
      padding: themed('infoShortPadding');
    }
  }
  @include themify($themes) {
    width: themed('infoShortWidth');
    background-color: themed('infoShortBackgroundColor');
  }
  .grid.center {
    margin: 0 auto;
    max-width: 571px;
    &.dealer-part {
      margin-bottom: 60px;
    }
  }

}

.warning {
  margin-top: 25px !important;
  text-align: center;
}

[disabled] {
  color: gray;
}
