@import "src/styles/vars";
@import "src/styles/mixins";

.documents-uploader {
  display: flex;
  flex-direction: column;
  align-items: center;

  @include respond(tablet-portrait) {
    padding: 0 15px;
  }

  h1 {
    margin: 30px 0;
    font-size: 24px;
  }

  &__cards {
    text-align: center;

    .card {
      display: inline-block;
      vertical-align: top;
      width: 250px;
      margin: 0 10px;

      @include respond(tablet-portrait) {
        &:not(:last-child) {
          margin-bottom: 10px;
        }
      }
    }

    .card-content {
      text-align: center;
      padding: 30px 15px;
    }
  }

  .card-head {
    &__title {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 44px;
      font-weight: 500;
    }

    &__description {
      margin-top: 15px;
      font-size: 12px;
      color: $color-grey;
    }
  }

  .card.selected {
    .card-head__description {
      color: $color-white
    }
  }

  &__button {
    margin-top: 80px;
    margin-bottom: 30px;
  }

  .error {
    padding: 30px;

    &__header {
      margin-bottom: 25px;
      font-size: 24px;
      text-align: center
    }
  }
  &__caption {
    margin-top: 25px;
    text-align: center;
    color: $color-grey;
    font-size: 12px;
  }
}

