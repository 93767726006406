.collapsible {
  &__header {
    cursor: pointer;
  }

  &__content {
    height: 0px;
    opacity: 0;
    overflow: hidden;
    transition: all 0.3s;

    &_collapsed {
      height: auto;
      opacity: 1;
      overflow: unset;
    }
  }
}
