@import 'styles/_mixins.scss';
.form-section {
  display: flex;
  flex-direction: column;
  padding-bottom: 44px;
  width: 100%;
  @include respond(tablet-portrait) {
    padding-bottom: 30px;
  }
}
