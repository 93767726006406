.tooltip {
  &.tippy-box {
    background-color: white;
    color: black;
    border-radius: 0;
    outline: 1px solid #C4C4C4;
    outline-offset: -1px;
  }

  .tippy-arrow {
    color: #C4C4C4;
  }
}
