@import 'styles/_vars.scss';
@import 'styles/themes.scss';

.not-working {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 600px;
  margin-top: 10%;

  background-color: #F6F2F1;

  &__result-header {
    text-align: center;
    margin-bottom: 40px;
  }

  &__closebutton {
    text-decoration: none;
    background: #052F6D;
    color: white;
    margin-top: 50px;
  }
}

.typography__sub-header{
  margin-top: 50px;
}
