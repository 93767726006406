@import 'styles/_vars.scss';
@import 'styles/_mixins.scss';
@import 'styles/themes.scss';

.container {
  .container-header {
    margin: 0 auto;
    font-size: 36px;
    letter-spacing: -.015em;
    margin-bottom: 47px;

    @include media($iphone) {
      font-size: 25px;
      text-align: center;
      padding: 0 24px; } }
  h1, h2 {
    text-align: center; }
  .container-caption {
    margin-top: 40px;
    font-size: 26px;
    @include themify($themes) {
      color: themed('colorPrime'); }
    letter-spacing: -.015em;
    margin-bottom: 25px;
    @include media($iphone) {
      font-size: 20px; } } }

.offer_line {
  margin-bottom: 5px;
  padding-top: 10px;
  @include media($iphone) {
    > * {
      width: 100%; } } }

.calc {
  margin-bottom: 35px;
  @include respond(tablet-landscape) {
    padding: 0 10px; }

  .checkbox-container {
    @include respond(tablet-portrait) {
      justify-content: center; } }

  .no_offers {
    margin-top: 15px; } }

.text {
  font-size: 17px;
  margin-bottom: 45px;

  p:last-child {
    text-decoration: underline;
    cursor: pointer; } }

.partners-section {
  margin-top: 40px;
  margin-bottom: 30px; }

.main__initial-fee-header {
  margin-top: 30px; }

.blocked {
  opacity: 0.5;
  pointer-events: none; }

.hidden {
  display: none; }

.complexity {
  .options {
    @include themify($themes) {
      width: themed('complexityOptionsWidth'); } }
  .select__value {
    overflow: hidden;
    white-space: nowrap; } }

.top-header {
  margin: 24px 0 60px;
  padding: 0;
  @include themify($themes) {
    margin: themed('topHeaderMargin'); }

  @include respond(tablet-landscape) {
    margin: 0 0 35px;
    font-size: 32px; } }
