.feedback {
  display: flex;
  flex-direction: column;
  width: 500px;
  margin: 0 auto;

  @media (max-width: 500px) {
    width: 100%;
  }

  &__question {
    height: 200px;
    line-height: 1.5;

    textarea {
      height: 100%;
      resize: none;
      padding: 15px 0;
    }
  }

  &__actions {
    display: flex;
    justify-content: space-around;
  }

  .button {
    @media (max-width: 500px) {
      min-width: 150px;
    }
  }
}
