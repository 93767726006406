@import 'vars';

* {
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
  font-family: $font, -apple-system, "Helvetica Neue", sans-serif;
  font-size: 13px;
  background-color: white;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1, h2, h3, h4, h5, h6, p {
  margin: 0;
  padding: 0;
}

a {
  text-decoration: none;
  color: inherit;
  cursor: pointer;
  border-bottom: 1px solid transparent;
  &:hover {
    border-bottom: 1px solid initial;
  }
}

li {
  list-style-type: none;
}

@-webkit-keyframes autofill {
  to {
    color: inherit;
    background: white;
  }
}

input:-webkit-autofill {
  -webkit-animation-name: autofill;
  -webkit-animation-fill-mode: both;
}
