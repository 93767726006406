@import 'styles/_vars.scss';
@import 'styles/themes.scss';

.checkbox-container {
  display: flex;
  align-items: center;
  font-size: 13px;
  height: $input-field-height;

  &:not(:last-child) {
    margin-right: 9px;
  }
  label {
    cursor: pointer;
    height: 18px;
  }
  input {
    width: 1px;
    height: 1px;
    opacity: 0;
    position: absolute;
  }

  .checkbox {
    margin-right: 12px;
    @include themify($themes) {
      width: themed('checkBoxSize');
      height: themed('checkBoxSize');
    }

    &__label {
      margin-right: 5px;

      &_clickable {
        text-decoration: underline;
        cursor: pointer;
      }
    }

    path {
      fill: transparent;
    }

    rect {
      @include themify($themes) {
        fill: themed('inputBg');
        stroke: themed('inputBorderColor');
      }
    }

    &_active {
      rect {
        @include themify($themes) {
          stroke: themed('colorPrime');
        }
      }
    }
  }

  &.checked {
    path {
      @include themify($themes) {
        fill: themed('colorPrime');
      }
    }

    rect {
      @include themify($themes) {
        stroke: themed('colorPrime');
      }
    }
  }
}
