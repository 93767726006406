@import 'styles/themes.scss';

.preloader {
  position: fixed;
  width: 100vw;
  height: 100vh;
  background-color: rgba(white,.8);
  top: 0;
  left: 0;
  z-index: 999;
  transition: opacity .3s;

  .wrapper {
    margin: 0 auto;
    text-align: center; }

  .line {
    display: inline-block;
    width: 15px;
    height: 15px;
    border-radius: 15px;
    background-color: #000;
    @include themify($themes) {
      background-color: themed('preloaderColor'); }
    &:nth-child(1) {
      animation: loadingC .5s .1s linear infinite; }
    &:nth-child(2) {
      animation: loadingC .5s .2s linear infinite; }
    &:nth-child(3) {
      animation: loadingC .5s .3s linear infinite; } }

  @keyframes loadingC {
    0 {
      transform: translate(0,0); }
    50% {
      transform: translate(0,15px); }
    100% {
      transform: translate(0,0); } } }
