.slick-list {
  padding: 15px 8px;
  div:focus {
    outline: none;
  }
}

.slick-dots li {
  margin: 0;
}
