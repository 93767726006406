@import "styles/_vars.scss";
@import "styles/_mixins.scss";

.logger-container {
  position: fixed;
  top: 0;
  left: 50%;
  padding: 16px;
  background-color: white;
  padding: 20px;
  border: 2px solid red;
  border-radius: 16px;
  transition: transform .2s;
  transform: translate(-50%, -100%);
  z-index: 1000; }
