.kasko {
  width: 100%;

  &__iframe {
    width: 100%;
    height: 780px;
    /*margin-bottom: 100px;*/
  }

  &__back {
    float: left;
    margin-bottom: 25px;
  }
}
