@import 'styles/_vars.scss';
@import 'styles/themes.scss';

.thank-you {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 600px;
  background-color: #F6F2F1;

  @include themify($themes) {
    color: themed('colorText');
    background-color: themed('blockBg2');
  }

  &__result-header {
    text-align: center;
    margin-bottom: 40px;
  }

  &__closebutton {
    text-decoration: none;
  }
}
