@import "styles/_mixins.scss";
@import "styles/_vars.scss";
@import "styles/themes.scss";

.alternative-field {
  display: flex;
  flex-direction: column;
  position: relative;
  margin-bottom: 16px;

  &__label {
    display: flex;
    align-items: center;
    white-space: nowrap;
    font-size: 13px;
    color: $color-black;
    margin-bottom: 6px;
    height: 14px;
    z-index: 2;

    @include themify($themes) {
      text-transform: themed("fieldLabelTextTransform");
      font-weight: themed("labelFontWeight");
      font-size: themed("fieldLabelFontSize");
      font-family: themed("fieldLabelFontFamily");
      margin-bottom: themed("fieldLabelMarginBottom");
    }

    &_skipped {
      height: unset;
    }
  }

  &__error {
    margin-top: 4px;
    color: #dd0a0a;
    font-size: 10px;
    line-height: 11px;
    min-height: 12px;
    font-style: italic;

    @include themify($themes) {
      color: themed("errorColor");
      font-style: themed("fieldErrorFontStyle");
      font-size: themed("fieldErrorFontSize");
    }

    &_skipped {
      min-height: unset;
    }
  }

  &__required-star {
    margin-right: 10px;
  }

  .select-wrapper {
    @include respond(tablet-landscape) {
      height: auto;
      padding: 0;
    }
  }
  .select-wrapper .select__value {
    @include respond(tablet-landscape) {
      display: flex;
      align-items: center;
      height: 47px;
      max-width: 90%;
      padding: 8px 20px;
      transform: none;
    }
  }
  .icon, .select-wrapper_active .icon {
    @include respond(tablet-landscape) {
      top: 15px;
      transform: none;
    }
  }
  .select-wrapper .option {
    @include respond(tablet-landscape) {
      padding: 15px 20px;
    }
  }
}
