@import "src/styles/themes";
@import "src/styles/mixins";
.equipment-item {
  display: flex;
  position: relative;
  min-width: 250px;
  height: 140px;
  margin: 0 10px;
  box-sizing: border-box;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;

  @include themify($themes) {
    border: 1px solid #{themed(equipmentBorderColor)};
  }
  @include respond(tablet-landscape) {
    margin: 0;
    &:not(:last-child) {
      margin-bottom: 20px;
    }
  }
  &.active {
    @include themify($themes) {
      border: 2px solid #{themed(equipmentActiveBorderColor)};
    }
    .equipment-item__tip-toggle {
      top: 9px;
      right: 9px;
    }
  }
  &:not(.active) {
    cursor: pointer;
  }
  &__name {
    max-width: 126px;
    overflow: hidden;
    font-size: 18px;
    font-weight: 500;
  }
  &__price {
    margin-top: 10px;
    font-size: 24px;
    font-weight: 500;
    @include themify($themes) {
      color: themed(colorPrime);
    }
  }
  &__tip {
    padding: 15px;
    @include respond(tablet-landscape) {
      display: none;
    }
    b {
      display: block;
      margin-bottom: 15px;
    }
    ul li {
      display: block;
      list-style: none;
      &:before {
        content: "\2022";
        font-weight: bold;
        display: inline-block;
        width: 1em;
        margin-left: -1em;
        @include themify($themes) {
          color: themed(colorPrime);
        }
      }
      &:not(:last-child) {
        margin-bottom: 5px;
      }
    }
    &--mobile {
      position: fixed;
      width: 100%;
      left: 0;
      bottom: 0;
      display: none;
      z-index: 11;
      background-color: #fff;
      text-align: left;
      & > div {
        padding: 30px 0 25px 50px;
      }
      svg {
        position: absolute;
        right: 10px;
        top: 10px;
      }
      @include respond(tablet-landscape) {
        display: block;
      }
    }
  }
  &__tip-toggle {
    position: absolute;
    top: 10px;
    right: 10px;
    width: 24px;
    height: 24px;
    @include respond(tablet-landscape) {
      display: none;
    }
    &--mobile {
      display: none;
      @include respond(tablet-landscape) {
        display: block;
      }
    }
  }
  &__tip-overlay {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 10;
    background-color: rgba(0,0,0,.3);
  }
}
