@import "src/styles/vars";

.files-uploader {
  width: 100%;

  input[type='file'] {
    visibility: hidden;
    width: 0;
    height: 0;
  }

  &__list {
  }

  &__list-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    line-height: 18px;

    &:not(:last-child) {
      margin-bottom: 5px;
    }
  }

  &__file-title {
    max-width: 50%;
    margin-right: auto;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__file-remove {
    margin-top: -3px;
    margin-left: 10px;
    cursor: pointer;
    &:hover {
      opacity: 0.8;
    }
  }

  &__button.button {
    width: 100%;
    margin-top: 15px;
    height: 48px;
    font-size: 16px;
    background-color: $color-lightblue;

    &:hover {
      background-color: darken($color-lightblue, 5);
    }

    svg {
      margin-right: 15px;
      margin-left: -15px;
    }
  }
}
