@import 'styles/_vars.scss';
@import 'styles/_mixins.scss';
@import 'styles/themes.scss';

.customer-income {
  display: flex;
  margin-top: 15px;
  color: $color-blue;
  @include themify($themes) {
    margin-left: themed('customerIncomeMarginLeft');
    margin-top: themed('customerIncomeMarginTop');
    color: themed('customerIncomeTipColor');
  }
  &_mobile {
    display: none;
  }
  @include respond(tablet-portrait) {
    &:not(&_mobile) {
      display: none;
    }
    &_mobile {
      margin: 30px auto 0;
      text-align: center;
      font-weight: bold;
    }
  }
  svg {
    width: 15px;
    height: 15px;
    margin-right: 10px;
    @include themify($themes) {
      display: themed('customerIncomeIconDisplay');
    }
  }
  span {
    max-width: 90%;
    font-weight: bold;
    @include themify($themes) {
      max-width: themed('customerIncomeMaxWidth');
      font-weight: themed('customerIncomeFontWeight');
    }
  }
}
