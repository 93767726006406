@import 'styles/_vars.scss';
@import 'styles/themes.scss';

.sidebar-container {
  position: absolute;
  top: 150px;
  left: -200px;
  width: 188px;
  height: 100%;
  h3 {
    @include themify($themes) {
      color: themed('colorPrime'); }
    font-size: 20px;
    margin-bottom: 20px;
    font-weight: 500; }

  .sticky {
    position: sticky;
    top: 20px;
    left: 0;
    width: 100%; }

  .wrapper {
    @include themify($themes) {
      border: 1px solid themed('colorPrime'); }
    padding: 30px 12px 30px 36px;
    h4 {
      color: #acacac;
      font-size: 12px;
      margin-bottom: 4px; }
    div {
      font-size: 16px;
      font-weight: 500;
      &:not(:last-child) {
        margin-bottom: 24px; } } }
  .monthly {
    @include themify($themes) {
      color: themed('colorPrime'); }
    font-size: 32px;
    font-weight: 700;
    span {
      font-size: 10px; } }

  .fix {
    background-color: transparent;
    width: 100%;
    height: 100px; }

  .block {
    height: 180px;
    background-color: $color-table-header;
    padding: 28px 10px 28px 36px;
    margin-top: 24px;
    h4 {
      font-size: 14px;
      font-weight: 500; }
    h5 {
      font-size: 10px;
      color: #acacac;
      margin-top: 8px; }
    .monthly {
      margin-top: 16px; }
    div {
      text-decoration: underline;
      @include themify($themes) {
        color: themed(colorPrime); }
      font-weight: 500;
      margin-top: auto;
      margin-bottom: 0;
      cursor: pointer; }
    .added {
      color: #acacac;
      text-decoration: none;
      svg {
        fill: transparent;
        rect {
          fill: transparent;
          stroke: transparent; }
        path {
          fill: $color-blue; } } } } }
