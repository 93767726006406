@import "styles/_vars.scss";
@import 'styles/_mixins.scss';

.header {
  height: 48px;
  background-color: $color-blue;

  @include media($tablet-portrait) {
    height: 35px;

    &__logo {
      max-width: 30%;
      overflow: hidden; } }

  .wrapper {
    width: 100%;
    max-width: 1040px;
    padding: 0 8px; }
  .nav {
    color: white;
    a {
      font-size: 13px;
      font-weight: 700;
      &:not(:last-child) {
        margin-right: 80px; }

      @include media($tablet-portrait) {
        font-size: 10px;

        &:not(:last-child) {
          margin-right: 10px; } } } }

  .active {
    text-decoration: underline; } }
