@import 'styles/themes.scss';
@import "styles/_mixins.scss";
.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(black, .2);
  z-index: 99;
  transition: opacity .3s;

  @include themify($themes) {
    background-color: themed('backdropColor');
  }
}
