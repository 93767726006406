@import "styles/_mixins.scss";

.kasko-footer {
  display: flex;
  align-items: center;
  flex-direction: column;

  color: white;
  background-color: #002e6b;
  height: 275px;

  &__payment {
    font-weight: 500;
    width: 70%;
    padding-top: 32px;
    margin-bottom: auto;

    @include respond(tablet-portrait) {
      width: 100%;
      padding: 20px;
    }
  }

  &__month-payment {
    display: flex;
    justify-content: space-between;
    font-size: 20px;
    margin-bottom: 24px;
  }

  &__month-payment-kasko {
    display: flex;
    justify-content: space-between;
    font-size: 30px;
  }

  &__month-payment-kasko-amount {
    display: flex;
    flex-direction: column;
    white-space: nowrap;
  }

  &__month-payment-kasko-plus {
    font-size: 16px;
    text-align: end;
    letter-spacing: 2px;
    font-weight: normal;
  }

  &__group-button {
    align-self: flex-start;
    margin-bottom: 32px;

    @include respond(tablet-portrait) {
      display: flex;
      justify-content: space-around;
      width: 100%;
    }
  }

  &__primary-button.button {
    background-color: #00abde;
    width: 225px;
  }

  &__secondary-button.button {
    color: white;
    margin-right: 280px;
    font-weight: 500;
    font-size: 15px;

    @include respond(tablet-portrait) {
      margin-right: auto;
    }
  }
}
