@import 'styles/_mixins.scss';

.debug-panel {
  background-color: white;
  padding: 20px;
  color: #000;

  &__table{
    margin-top: 20px;
  }

  tr{
    border-bottom: 1px solid gray;
  }

  td{
    padding: 5px;
  }

  td:first-child{
  }

  td:nth-child(2){
    font-weight: bold;
  }

  td:last-child{
    min-width: 300px;
  }

  tr:first-child td:nth-child(2){
    font-weight: normal;
  }

  &__link{
    color: #0b72b8;
  }

  .info{
    padding: 20px 10px;
    word-break: break-word;
  }
}
