@import 'styles/_vars.scss';
@import 'styles/_mixins.scss';
@import 'styles/themes.scss';
.form {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 600px;
  padding: 60px 92px 24px;
  @include themify($themes) {
    background-color: themed('formBackgroundColor')
  }

  @include respond(tablet-portrait) {
    padding: 26px 10px;
    border: 1px solid rgba(168, 168, 168, 0.5);
  }
  &-footer {
    margin-top: auto;
  }
}
