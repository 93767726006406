@import 'styles/_vars.scss';
@import 'styles/_mixins.scss';
@import 'styles/themes.scss';

.slider {
  padding: 0 110px 80px 110px;
  @include themify($themes) {
    margin-top: themed('sliderMarginTop'); }
  @include media($iphone) {
    padding: 60px 0; }

  .display {
    margin-right: 24px; }



  &__price-header {
    @include themify($themes) {
      font: themed('priceFont'); } }

  &__price {
    margin-top: 8px;
    font-weight: 500;
    font-size: 18px;
    margin-bottom: 30px;

    @include themify($themes) {
      font: themed('priceFont'); } }

  &__price_description {
    @include themify($themes) {
      color: themed('priceDescriptionColor'); } }

  &__input-description {
    margin-bottom: 6px;
    align-self: flex-start;
    @include themify($themes) {
      color: themed('sliderInputDescriptionColor');
      font-weight: themed('sliderInputDescriptionFontWeight'); } }

  &__blocked {
    opacity: 0.5;
    pointer-events: none; }

  &__input-description,
  &__input-currency {
    font-weight: 500; }

  &__input-container {
    display: flex;
    align-items: center;

    .input-field {
      margin-bottom: 0; } }

  &__input {
    height: 64px;
    width: 248px;
    text-align: center;
    border: $input-field-border;
    margin: auto;

    > div {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;

      > div {
        display: none; } }

    input {
      border: none;
      width: 100%;
      height: 100%;
      font-size: 34px;
      font-weight: 500;
      margin: auto;
      text-align: center;
      transform: translateY(-3px);

      @include themify($themes) {
        color: themed('sliderInputColor');
        font-weight: themed('sliderInputFontWeight'); } } }

  &__ranger {
    width: 504px;
    //border-bottom: 1px solid black
    overflow: hidden;
    @include media($iphone) {
      width: 100%;
      margin-top: 40px; } }

  &__price.typography {
    margin-top: 10px;
    margin-bottom: 40px; }

  .track {
    height: 240px;

    border-radius: 2px 2px 0 0;
    position: relative;
    &:after {
      content: '';
      display: block;
      width: 100%;
      height: 1px;
      margin-bottom: 15px;
      background-color: black; } }

  .track-caption {
    margin-top: 15px;
    text-align: center; }

  &__handle {
    display: flex;
    align-items: center;
    position: relative;
    width: 60px;
    height: 60px;
    z-index: 1 !important;
    justify-content: space-around;
    cursor: pointer;
    border-radius: 100%;
    @include themify($themes) {
      background: linear-gradient(to right, themed('colorPrime') 50%, lighten(themed('colorPrime'), 40%) 51%); }
    @include themifySliderBg($themes) {
      background: themed('specificColorSliderBg'); }
    transform: translate(-50%, -50%);
    transition: left .01s;
    &:focus {
      outline: none; }
    >div {
      font-size: 14px;
      font-weight: 700;
      pointer-events: none;
      color: white; } }

  &__drag-icon {
    position: absolute;
    width: 40px;
    height: 40px;
    top: 60px; }

  .backlog {
    width: 100%;
    height: 100%;
    background-color: white;
    opacity: 0.7;
    border-left: 1px solid black;
    @include themifySliderBg($themes) {
      background-color: themed('sliderBacklogBg'); } } }
