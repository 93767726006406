@import 'styles/themes.scss';
@import 'styles/_mixins.scss';

.typography {
  &__main-header {
    font-size: 40px;

    @include themify($themes) {
      font-size: themed('mainHeaderFontSize');
      font-weight: themed('mainHeaderFontWeight');
    }
  }

  &__result-header {
    font-size: 30px;
    font-weight: 500;
    margin-bottom: 15px;
  }

  &__block-header {
    font-size: 25px;
    font-weight: 500;
    margin-bottom: 30px;
    //color: #BF909C;

    @include themify($themes) {
      margin: themed('blockHeaderMargin');
      color: themed('blockHeaderFontColor');
      font-size: themed('blockHeaderFontSize');
      font-weight: themed('blockHeaderFontWeight');
    }
  }

  &__section-header {
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 15px;

    @include themify($themes) {
      margin-bottom: themed('sectionHeaderMarginBottom');
      font-size: themed('sectionHeaderFontSize');
      font-weight: themed('sectionHeaderFontWeight');
      color: themed('sectionHeaderColor');
    }

    @include respond(tablet-portrait) {
      font-size: 14px;
    }
  }

  &__sub-header {
    margin-top: 5px;
    font-size: 18px;
    text-align: center;
    font-weight: 500;
  }

  &__h5 {
    font-size: 13px;

    @include themify($themes) {
      font-size: themed('h5FontSize');
      font-weight: themed('h5FontWeight');
    }
  }

  &__paragraph {
    font-size: 14px;
  }

  &__margin-paragraph {
    font-size: 14px;
    margin-bottom: 25px;
    text-align: center;
  }

  &__caption {
    font-size: 10px;
  }
}
