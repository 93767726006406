@import 'styles/_vars.scss';
@import 'styles/themes.scss';

.thank-you {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 600px;

  background-color: #F6F2F1;

  &__result-header {
    text-align: center;
    margin-bottom: 40px;
  }

  &__closebutton{
    padding-top: 50px;
    font-size: 18px;
    text-decoration: underline;
    color: blue;
    font-weight: bold;
  }
}
