@import "styles/themes.scss";

.app {
  &__container {
    @include themify($themes) {
      font-family: themed('fontFamily');
      background-color: themed('colorBg');
      color: themed('colorText');

      h1 {
        font-family: themed('headlineFontFamily');
        font-weight: themed('headlineFontWeight');
      }
    }
  }
}
