@import 'styles/_vars.scss';
@import 'styles/_mixins.scss';
.form-footer {
  @include respond(tablet-portrait) {
    margin: 0 auto;
    max-width: 315px;
    flex-direction: column;
    .button_flat {
      height: 50px;
      order: 2;
    }
  }
  button {
    margin: auto;
    font-weight: 100;
  }

}
