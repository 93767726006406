.personal-data-agreement {
  &__personal-info {
    margin: 20px 0px 20px 20px;
    text-align: left;

    & li {
      margin: 5px 0;
      list-style: circle;
    }
  }
}

.personal-data-agreement-mazda {
  &__header {
    display: block;
    font-weight: bold;
    margin-bottom: 20px;
  }

  p {
    text-indent: 20px;
  }
  li {
    list-style: disc;
  }

  &__footer {
    table td, tr {
      border: none;
    }
  }
}
