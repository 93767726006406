@import "vars";

$default-primary-color: #002c5f;
$chery-primary-color: #cd2626;
$mazda-primary-color: #000000;
$mazda-red: #910a2d;
$suzuki-primary-color: #27509b;
$hyundai-primary-color: #002c5f;
$subaru-primary-color: #2962ff;
$mitsubishi-primary-color: black;
$mitsubishi-secondary-color: #ed0000;
$euroasiamotors-primary-color: #ff6f00;
$kiamotors-primary-color: #f2f2f2;
$autodrom-primary-color: #2554ff;
$inchcapecf-primary-color: #131313;
$inchcapecf-price-color: #da291c;
$inchcapecf-bg-color-black: #000000;

$themes: (
  default: (
    colorBg: #ffffff,
    colorText: #000000,
    colorPrime: $hyundai-primary-color,
    errorColor: #c70e0e,
    buttonColor: $hyundai-primary-color,
    buttonTextColor: $color-white,
    buttonDisabledColor: #777673,
    flatButtonColor: $color-grey,
    buttonHoverColor: lighten(#000000, 15%),
    fontFamily: "HyundaiSansText",
    headlineFontFamily: "HyundaiSansHead",
    mainHeaderFontSize: 42px,
    mainHeaderFontWeight: 500,
    blockHeaderFontColor: #854253,
    blockHeaderFontSize: 30px,
    blockHeaderFontWeight: 500,
    blockHeaderMarginBottom: 64px,
    h5FontSize: 16px,
    h5FontWeight: 500,

    offerPriceColor: $suzuki-primary-color,

    labelFontWeight: bold,
    fieldLabelFontSize: 16px,
    fieldLabelFontFamily: "HyundaiSansHead",
    fieldLabelMarginBottom: 7px,
    fieldErrorFontStyle: normal,
    fieldErrorFontSize: 12px,
    fieldMarginBottom: 32px,
    fieldMarginRight: 32px,

    activeInputBorder: 1px solid transparent,
    inputActiveBoxShadow: 0 8px 24px rgba(0, 0, 0, 0.16),
    //backdropColor: transparent,
    // Шаги на главной странице
    introductionFontColorStep: black,
    introductionHeaderFontSize: 30px,
    introductionHeaderMarginBottom: 64px,
    introductionFontWeight: normal,
    introductionFontSize: 14px,
    introductionNumberDisplay: flex,
    introductionNumberWidth: 45px,
    introductionNumberHeight: 45px,
    introductionNumberBackgroundColor: #fff,
    introductionNumberBorderRadius: 50%,
    introductionTextMaxWidth: 215px,
    // Табы
    tabBorderRight: 1px solid #f2f2f2,
    tabBorderBottom: 1px solid #f2f2f2,
    tabBackgroundColor: #fff,
    tabActiveBackgroundColor: #f2f2f2,
    tabHeight: 60px,
    tabMargin: 0 0 5px 0,
  ),
  chery_red: (
    colorBg: #ffffff,
    colorText: #000000,
    colorPrime: $chery-primary-color,

    buttonColor: $chery-primary-color,
    buttonTextColor: $color-white,
    buttonDisabledColor: rgba($chery-primary-color, 0.4),
    flatButtonColor: $color-grey,

    fontFamily: $font,
    blockHeaderFontColor: $chery-primary-color,

    advantagePointBorderColor: $chery-primary-color,
    advantageButtonColor: $chery-primary-color,

    offerPriceColor: $chery-primary-color,
    // Шаги на главной странице
    introductionFontColorStep: black,
    introductionHeaderFontSize: 30px,
    introductionHeaderMarginBottom: 64px,
    introductionFontWeight: normal,
    introductionFontSize: 14px,
    introductionNumberDisplay: flex,
    introductionNumberWidth: 45px,
    introductionNumberHeight: 45px,
    introductionNumberBackgroundColor: #fff,
    introductionNumberBorderRadius: 50%,
    introductionTextMaxWidth: 215px,
    // Табы
    tabBorderRight: 1px solid #f2f2f2,
    tabBorderBottom: 1px solid #f2f2f2,
    tabBackgroundColor: #fff,
    tabActiveBackgroundColor: #f2f2f2,
    tabHeight: 60px,
    tabMargin: 0 0 5px 0,
  ),
  chery_tiggo: (
    colorBg: #ffffff,
    colorText: #000000,
    colorPrime: $chery-primary-color,

    buttonColor: $chery-primary-color,
    buttonTextColor: $color-white,
    buttonDisabledColor: rgba($chery-primary-color, 0.4),
    flatButtonColor: $color-grey,

    fontFamily: $font,
    topHeaderMargin: 24px 0 30px,
    blockHeaderMargin: 0 0 60px,
    blockHeaderFontColor: $chery-primary-color,
    blockHeaderFontWeight: normal,
    advantagePointBorderColor: $chery-primary-color,
    advantageButtonColor: $chery-primary-color,

    customerIncomeTipColor: $chery-primary-color,
    customerIncomeIconDisplay: none,
    customerIncomeMarginLeft: 15px,
    customerIncomeMaxWidth: 100%,

    infoShortWidth: 100%,
    infoShortBackgroundColor: #f7f7f7,
    infoShortPadding: 30px 227px,
    infoShortButtonBackgroundColor: #fff,
    infoShortButtonPadding: 14px 70px,
    infoShortStepsPadding: 0 0 30px,

    offerPriceColor: $chery-primary-color,
    // Шаги на главной странице
    introductionFontColorStep: black,
    introductionHeaderFontSize: 30px,
    introductionHeaderMarginBottom: 64px,
    introductionFontWeight: normal,
    introductionFontSize: 14px,
    introductionNumberDisplay: flex,
    introductionNumberWidth: 45px,
    introductionNumberHeight: 45px,
    introductionNumberBackgroundColor: #fff,
    introductionNumberBorderRadius: 50%,
    introductionTextMaxWidth: 215px,
    // Табы
    tabBorderRight: 1px solid #f2f2f2,
    tabBorderBottom: 1px solid #f2f2f2,
    tabBackgroundColor: #fff,
    tabActiveBackgroundColor: #f2f2f2,
    tabHeight: 60px,
    tabMargin: 0 0 5px 0,
    equipmentBorderColor: #b7b7b7,
    equipmentActiveBorderColor: $chery-primary-color,
  ),
  mazda: (
    colorBg: #ffffff,
    colorText: #000000,
    colorPrime: $mazda-primary-color,

    buttonColor: $mazda-primary-color,
    buttonTextColor: $color-white,
    buttonDisabledColor: #9d9d9d,
    flatButtonColor: $mazda-primary-color,
    buttonHoverColor: $mazda-red,
    buttonTextTransform: uppercase,

    inputHeight: 42px,
    selectTop: 62px,

    fontFamily: "Mazda Type Cyrillic",
    blockHeaderFontColor: $mazda-primary-color,

    advantagePointBorderColor: #9d9d9d,
    advantageButtonColor: $mazda-primary-color,

    offerPriceColor: $mazda-red,

    fieldLabelTextTransform: uppercase,
    labelFontWeight: bold,

    inputBorder: 1px solid #d5d5d5,
    formInputBorder: 1px solid #d5d5d5,

    selectFontSize: 15px,
    selectFontWeight: normal,

    formBackgroundColor: #f6f3f2,
    sectionHeaderFontSize: 25px,
    sectionHeaderMarginBottom: 32px,
    sectionHeaderFontWeight: bold,
    sectionHeaderColor: #303234,

    sliderInputFontWeight: 700,

    // Шаги на главной странице
    introductionFontColorStep: black,
    introductionHeaderFontSize: 30px,
    introductionHeaderMarginBottom: 64px,
    introductionFontWeight: normal,
    introductionFontSize: 14px,
    introductionNumberDisplay: flex,
    introductionNumberWidth: 45px,
    introductionNumberHeight: 45px,
    introductionNumberBackgroundColor: #fff,
    introductionNumberBorderRadius: 50%,
    introductionTextMaxWidth: 215px,
    // Табы
    tabBorderRight: 1px solid #f2f2f2,
    tabBorderBottom: 1px solid #f2f2f2,
    tabBackgroundColor: #fff,
    tabActiveBackgroundColor: #f2f2f2,
    tabHeight: 60px,
    tabMargin: 0 0 5px 0,
  ),
  suzuki: (
    colorBg: #ffffff,
    colorText: #000000,
    colorPrime: $suzuki-primary-color,

    buttonColor: $suzuki-primary-color,
    buttonTextColor: $color-white,
    buttonDisabledColor: rgba($suzuki-primary-color, 0.4),
    flatButtonColor: $color-grey,
    buttonHoverColor: #1d3b72,
    buttonTextTransform: uppercase,

    fontFamily: "SuzukiPRO",
    headlineFontFamily: "SuzukiPROHeadline",
    blockHeaderFontColor: $suzuki-primary-color,

    offerPriceColor: $suzuki-primary-color,

    fieldLabelTextTransform: uppercase,

    inputBorder: 1px solid #d9d9d9,
    formInputBorder: 1px solid #d9d9d9,
    inputFocusBorderColor: $suzuki-primary-color,

    // Шаги на главной странице
    introductionHeaderFontSize: 30px,
    introductionHeaderMarginBottom: 64px,
    introductionFontWeight: normal,
    introductionFontSize: 14px,
    introductionNumberDisplay: flex,
    introductionNumberWidth: 45px,
    introductionNumberHeight: 45px,
    introductionNumberBackgroundColor: #fff,
    introductionNumberBorderRadius: 50%,
    introductionTextMaxWidth: 215px,
    // Табы
    tabBorderRight: 1px solid #f2f2f2,
    tabBorderBottom: 1px solid #f2f2f2,
    tabBackgroundColor: #fff,
    tabActiveBackgroundColor: #f2f2f2,
    tabHeight: 60px,
    tabMargin: 0 0 5px 0,
  ),
  hyundai: (
    colorBg: #ffffff,
    colorText: #000000,
    colorPrime: $hyundai-primary-color,
    errorColor: #c70e0e,
    buttonColor: $hyundai-primary-color,
    buttonTextColor: $color-white,
    buttonDisabledColor: #777673,
    flatButtonColor: $color-grey,
    buttonHoverColor: lighten(#000000, 15%),
    fontFamily: "HyundaiSansText",
    headlineFontFamily: "HyundaiSansHead",
    mainHeaderFontSize: 42px,
    mainHeaderFontWeight: 500,
    blockHeaderFontColor: black,
    blockHeaderFontSize: 30px,
    blockHeaderFontWeight: 500,
    blockHeaderMarginBottom: 64px,
    h5FontSize: 16px,
    h5FontWeight: 500,

    offerPriceColor: $suzuki-primary-color,

    labelFontWeight: bold,
    fieldLabelFontSize: 16px,
    fieldLabelFontFamily: "HyundaiSansHead",
    fieldLabelMarginBottom: 7px,
    fieldErrorFontStyle: normal,
    fieldErrorFontSize: 12px,
    fieldMarginBottom: 32px,
    fieldMarginRight: 32px,

    activeInputBorder: 1px solid transparent,
    inputActiveBoxShadow: 0 8px 24px rgba(0, 0, 0, 0.16),
    //backdropColor: transparent,
    // Шаги на главной странице
    introductionFontColorStep: black,
    introductionHeaderFontSize: 30px,
    introductionHeaderMarginBottom: 64px,
    introductionFontWeight: normal,
    introductionFontSize: 14px,
    introductionNumberDisplay: flex,
    introductionNumberWidth: 45px,
    introductionNumberHeight: 45px,
    introductionNumberBackgroundColor: #fff,
    introductionNumberBorderRadius: 50%,
    introductionTextMaxWidth: 215px,
    // Табы
    tabBorderRight: 1px solid #f2f2f2,
    tabBorderBottom: 1px solid #f2f2f2,
    tabBackgroundColor: #fff,
    tabActiveBackgroundColor: #f2f2f2,
    tabHeight: 60px,
    tabMargin: 0 0 5px 0,
  ),
  subaru: (
    colorBg: #ffffff,
    colorText: #000000,
    colorPrime: #2962ff,

    buttonColor: $color-white,
    buttonTextColor: $subaru-primary-color,
    buttonDisabledTextColor: rgba($subaru-primary-color, 0.4),
    buttonBorder: 1px solid $subaru-primary-color,
    buttonDisabledBorder: 1px solid rgba($subaru-primary-color, 0.4),
    buttonBorderRadius: 5px,
    buttonFontSize: 18px,
    flatButtonColor: $subaru-primary-color,
    buttonHoverColor: $subaru-primary-color,
    buttonHoverTextColor: $color-white,

    advantagePointBorderColor: $subaru-primary-color,
    advantageButtonColor: $subaru-primary-color,

    blockHeaderFontColor: #2962ff,

    offerPriceColor: $subaru-primary-color,

    inputBorder: solid 1px rgba(0, 0, 0, 0.15),
    formInputBorder: 1px solid rgba(0, 0, 0, 0.15),
    inputBorderRadius: 4px,

    // Шаги на главной странице
    introductionFontColorStep: black,
    introductionHeaderFontSize: 30px,
    introductionHeaderMarginBottom: 64px,
    introductionFontWeight: normal,
    introductionFontSize: 14px,
    introductionNumberDisplay: flex,
    introductionNumberWidth: 45px,
    introductionNumberHeight: 45px,
    introductionNumberBackgroundColor: #fff,
    introductionNumberBorderRadius: 50%,
    introductionTextMaxWidth: 215px,
    // Табы
    tabBorderRight: 1px solid #f2f2f2,
    tabBorderBottom: 1px solid #f2f2f2,
    tabBackgroundColor: #fff,
    tabActiveBackgroundColor: #f2f2f2,
    tabHeight: 60px,
    tabMargin: 0 0 5px 0,
  ),
  mitsubishimotors: (
    colorBg: #ffffff,
    colorText: #000000,
    colorPrime: $mitsubishi-primary-color,

    buttonColor: $mitsubishi-secondary-color,
    buttonTextColor: $color-white,
    buttonDisabledColor: rgba($mitsubishi-secondary-color, 0.4),
    flatButtonColor: $color-grey,
    buttonHoverShadow: $shadow-mild,

    fontFamily: "MMC",
    blockHeaderFontColor: $mitsubishi-secondary-color,

    advantagePointBorderColor: $mitsubishi-secondary-color,
    advantageButtonColor: $mitsubishi-secondary-color,

    offerPriceColor: $mitsubishi-secondary-color,

    inputBorder: 1px solid #c5c5c5,
    formInputBorder: 1px solid #c5c5c5,
    inputBorderRadius: 5px,

    partnerContainerJustifyContent: flex-end,
    partnerLogoWidth: 80px,
    partnerLogoWidthImg: 60px,

    // Шаги на главной странице
    introductionFontColorStep: black,
    introductionHeaderFontSize: 30px,
    introductionHeaderMarginBottom: 64px,
    introductionFontWeight: normal,
    introductionFontSize: 14px,
    introductionNumberDisplay: flex,
    introductionNumberWidth: 45px,
    introductionNumberHeight: 45px,
    introductionNumberBackgroundColor: #fff,
    introductionNumberBorderRadius: 50%,
    introductionTextMaxWidth: 215px,
    // Табы
    tabBorderRight: 1px solid #f2f2f2,
    tabBorderBottom: 1px solid #f2f2f2,
    tabBackgroundColor: #fff,
    tabActiveBackgroundColor: #f2f2f2,
    tabHeight: 60px,
    tabMargin: 0 0 5px 0,
  ),
  euroasiamotors: (
    colorBg: #ffffff,
    colorText: #000000,
    colorPrime: $euroasiamotors-primary-color,
    errorColor: $euroasiamotors-primary-color,

    buttonColor: $euroasiamotors-primary-color,
    buttonTextColor: $color-white,
    buttonDisabledColor: rgba($euroasiamotors-primary-color, 0.2),
    buttonBorderRadius: 27px,
    buttonHoverColor: #ff5200,

    inputBorder: 1px solidrgba(95, 95, 95, 0.2),
    formInputBorder: 1px solid rgba(95, 95, 95, 0.2),
    inputBorderRadius: 4px,

    fontFamily: "Lato",
    blockHeaderFontSize: 30px,
    blockHeaderFontWeight: 800,

    priceFont: 800 26px Segoe UI,

    // Шаги на главной странице
    introductionFontColorStep: black,
    introductionHeaderFontSize: 30px,
    introductionHeaderMarginBottom: 64px,
    introductionFontWeight: normal,
    introductionFontSize: 14px,
    introductionNumberDisplay: flex,
    introductionNumberWidth: 45px,
    introductionNumberHeight: 45px,
    introductionNumberBackgroundColor: #fff,
    introductionNumberBorderRadius: 50%,
    introductionTextMaxWidth: 215px,
    // Табы
    tabBorderRight: 1px solid #f2f2f2,
    tabBorderBottom: 1px solid #f2f2f2,
    tabBackgroundColor: #fff,
    tabActiveBackgroundColor: #f2f2f2,
    tabHeight: 60px,
    tabMargin: 0 0 5px 0,
  ),
  inchcapecf: (
    colorBg: #ffffff,
    colorText: #000000,
    buttonHoverColor: $inchcapecf-bg-color-black,
    buttonColor: $inchcapecf-price-color,
    offerPriceColor: $inchcapecf-price-color,
    colorPrime: $inchcapecf-price-color,
    buttonTextColor: $color-white,
    specificColorSliderBg: linear-gradient(to right, #131313 50%, #da291c 51%),
  ),
  kiamotors: (
    colorBg: #ffffff,
    colorText: #000000,
    bigFontSize: 144px,
    fontStyleHeader: $font-style-italic,
    gridColumnGap: 26px,
    colorPrime: #000,
    errorColor: #c70e0e,
    buttonColor: #000,
    buttonTextColor: $color-white,
    buttonDisabledColor: #777673,
    buttonDisabledOpacity: 0.2,
    flatButtonColor: $color-grey,
    buttonHoverColor: lighten(#000, 10%),
    buttonHeight: 56px,
    buttonMinWidth: 188px,
    buttonFontSize: 16px,

    fontFamily: "KIA",
    headlineFontFamily: "KIA",
    headlineFontWeight: 100,
    mainHeaderFontSize: 42px,
    mainHeaderFontWeight: 600,
    blockHeaderFontColor: black,
    blockHeaderFontSize: 30px,
    blockHeaderFontWeight: 600 !important,
    blockHeaderMarginBottom: 64px,
    blockCaptionFontWeight: 600,
    blockCaptionColor: #303234,
    h5FontSize: 16px,
    h5FontWeight: 500,
    selectFontSize: 14px,
    selectTop: 71px,

    offerPriceColor: #000,

    labelFontWeight: normal,
    fieldLabelFontSize: 16px,
    fieldLabelFontFamily: "KIA",
    fieldLabelMarginBottom: 7px,
    fieldErrorFontStyle: normal,
    fieldErrorFontSize: 12px,
    fieldMarginBottom: 32px,
    fieldMarginRight: 32px,

    inputBorder: 1px solid #e4dcd3,
    activeInputBorder: 1px solid transparent,
    formInputBorder: 1px solid $form-input-border-color,
    inputHeight: 48px,
    inputFontSize: 13px,
    inputFontWeight: 400,
    inputPadding: 0px 20px,
    inputActiveBoxShadow: 0 8px 24px rgba(0, 0, 0, 0.16),
    backdropColor: transparent,
    checkBoxSize: 20px,
    questionaryTabActiveBackgroundColor: #f2f2f2,
    questionaryTabActiveColor: #000,
    questionaryTabFontSize: 13px,
    questionaryTabFontWeight: normal,
    questionaryTabActiveFontWeight: normal,
    customerIncomeTipColor: #e6001d,
    customerIncomeIconDisplay: none,

    complexityOptionsWidth: 100%,

    // Шаги на главной странице
    introductionFontColorStep: black,
    introductionHeaderFontSize: 30px,
    introductionHeaderMarginBottom: 64px,
    introductionFontWeight: normal,
    introductionFontSize: 14px,
    introductionNumberDisplay: flex,
    introductionNumberWidth: 45px,
    introductionNumberHeight: 45px,
    introductionNumberBackgroundColor: #fff,
    introductionNumberBorderRadius: 50%,
    introductionTextMaxWidth: 215px,

    formBorder: none,
    formBackgroundColor: #f2f2f2,

    sectionHeaderFontSize: 25px,
    sectionHeaderMarginBottom: 32px,
    sectionHeaderFontWeight: bold,
    sectionHeaderColor: #303234,
    sliderInputDescriptionColor: #000,
    sliderInputDescriptionFontWeight: normal,
    sliderInputFontWeight: 700,

    // Табы
    tabBorderRight: 1px solid #f2f2f2,
    tabBorderBottom: 1px solid #f2f2f2,
    tabBackgroundColor: #fff,
    tabActiveBackgroundColor: #f2f2f2,
    tabHeight: 60px,
    tabMargin: 0 0 5px 0,
  ),
  volvomotors: (
    colorBg: #ffffff,
    colorText: #000000,
    colorPrime: #1c6bba,

    buttonColor: $default-primary-color,
    buttonTextColor: $color-white,
    buttonDisabledColor: rgba($default-primary-color, 0.4),
    flatButtonColor: $color-grey,
    buttonHoverShadow: $shadow-mild,
    fieldLabelFontSize: 13px,
    fontFamily: $font,
    blockHeaderFontColor: #1c6bba,

    advantagePointBorderColor: $default-primary-color,
    advantageButtonColor: $default-primary-color,

    offerPriceColor: #1c6bba,
    offerHeaderBackgroundColor: #eee,
    offerHeaderColor: grey,

    // footerBackgroundColor: $footer-background-color,
    inputBorder: 1px solid $color-grey,
    inputFontSize: 13px,
    inputFontWeight: normal,
    formInputBorder: 1px solid $form-input-border-color,
    infoShortWidth: 100%,
    infoShortBackgroundColor: #f6f2f1,
    infoShortPadding: 30px 200px,
    infoShortButtonBackgroundColor: #fff,
    infoShortButtonPadding: 14px 70px,
    infoShortStepsPadding: 0 0 30px,
    customerIncomeTipColor: #1c6bba,
    customerIncomeIconDisplay: none,
    customerIncomeMarginTop: 10px,
    customerIncomeMaxWidth: 100%,
    customerIncomeFontWeight: 500,
    headlineFontWeight: normal,
    stepsHeaderMarginBottom: 10px,
    selectTop: calc(100% - 1px),
    selectFontSize: 15px,
    selectFontWeight: normal,
    //selectFontColor: #666666,
    selectTransform: translateY(6px),
    fieldLabelColor: #a8a8a8,
    sliderMarginTop: 100px,
    sliderInputDescriptionColor: #1c6bba,
    sliderInputColor: #1c6bba,

    // Шаги на главной странице
    introductionFontColorStep: black,
    introductionHeaderFontSize: 30px,
    introductionHeaderMarginBottom: 64px,
    introductionFontWeight: normal,
    introductionFontSize: 14px,
    introductionNumberDisplay: flex,
    introductionNumberWidth: 45px,
    introductionNumberHeight: 45px,
    introductionNumberBackgroundColor: #fff,
    introductionNumberBorderRadius: 50%,
    introductionTextMaxWidth: 215px,
    // Табы
    tabBorderRight: 1px solid #f2f2f2,
    tabBorderBottom: 1px solid #f2f2f2,
    tabBackgroundColor: #fff,
    tabActiveBackgroundColor: #f2f2f2,
    tabHeight: 60px,
    tabMargin: 0 0 5px 0,
  ),
  autodrom: (
    colorBg: #ffffff,
    colorText: #000000,
    colorPrime: $autodrom-primary-color,

    buttonColor: $autodrom-primary-color,
    buttonTextColor: $color-white,
    buttonDisabledColor: #777673,
    flatButtonColor: $color-grey,
    buttonHoverColor: #103a71,
    buttonMinWidth: 188px,

    customerIncomeTipColor: #000,
    customerIncomeIconDisplay: none,
    customerIncomeMaxWidth: 100%,

    fontFamily: "Play",
    headlineFontFamily: "Oswald",
    blockHeaderFontColor: black,
    blockHeaderFontSize: 30px,
    blockHeaderFontWeight: 500,

    advantagePointBorderColor: $autodrom-primary-color,
    advantageButtonColor: $autodrom-primary-color,

    offerPriceColor: $autodrom-primary-color,
    // Шаги на главной странице
    introductionFontColorStep: $autodrom-primary-color,
    introductionHeaderFontSize: 30px,
    introductionHeaderMarginBottom: 64px,
    introductionFontWeight: normal,
    introductionFontSize: 14px,
    introductionNumberDisplay: flex,
    introductionNumberWidth: 45px,
    introductionNumberHeight: 45px,
    introductionNumberBackgroundColor: #fff,
    introductionNumberBorderRadius: 50%,
    introductionTextMaxWidth: 215px,
    // Табы
    tabBorderRight: 1px solid #f2f2f2,
    tabBorderBottom: 1px solid #f2f2f2,
    tabBackgroundColor: #fff,
    tabActiveBackgroundColor: #f2f2f2,
    tabHeight: 60px,
    tabMargin: 0 0 5px 0,
  ),
  exeed: (
    colorBg: rgb(14, 18, 20),
    colorText: #ffffff,
    colorPrime: #c78e66,
    errorColor: #c78e66,
    blockBg1: #24282b,
    blockBg2: #30363a,

    fontFamily: "Exeed",
    headlineFontFamily: "Exeed Header",
    mainHeaderFontSize: 36px,
    mainHeaderFontWeight: 400,
    blockHeaderFontColor: #ffffff,
    blockHeaderFontSize: 28px,
    blockHeaderFontWeight: 400,
    blockHeaderMarginBottom: 54px,
    /**/ h5FontSize: 16px,
    /**/ h5FontWeight: 500,

    labelFontWeight: 400,
    fieldLabelFontSize: 14px,
    fieldLabelFontFamily: "Exeed",
    fieldLabelTransform: translate(0, 18px) scale(1),
    fieldErrorFontStyle: normal,
    fieldErrorFontSize: 14px,
    inputBg: transparent,
    inputBorder: 1px solid #7d8082,
    inputFocusBorder: 1px solid #c78e66,
    inputFocusBorderColor: #c78e66,
    inputBorderColor: #7d8082,
    inputFontSize: 14px,
    inputFontWeight: 400,
    inputFontColor: #ffffff,
    selectBg: #181b1d,
    selectFontColor: #ffffff,
    selectSelectionColor: #30363a,
    formInputBorder: 1px solid #7d8082,
    buttonColor: transparent,
    flatButtonColor: #ffffff,
    buttonHoverColor: transparent,
    buttonDisabledColor: transparent,
    buttonBorder: 1px solid #7d8082,
    flatButtonBorder: 1px solid #7d8082,
    buttonHoverBorder: 1px solid #c78e66,
    buttonDisabledBorder: 1px solid #30363a,
    buttonTextColor: #ffffff,
    buttonHoverTextColor: #c78e66,
    buttonDisabledTextColor: #30363a,

    priceFont: 700 28px "Exeed",
    priceDescriptionColor: #a3a5a6,
    sliderInputDescriptionColor: #a3a5a6,
    sliderInputBg: #181b1d,
    sliderInputBorder: 1px solid #7d8082,
    sliderInputColor: #ffffff,
    sliderInputFontWeight: 700,
    sliderBacklogBg: #181b1d,
    specificColorSliderBg: rgb(36, 40, 43),
    offerPriceColor: #ffffff,
    offerBorder: none,
    offerHeaderColor: #ffffff,
    offerPlusBg: transparent,
    offerPlusBorderColor: #ffffff,
    offerLineColor: #7d8082,
    offerDescriptionColor: #a3a5a6,

    customerIncomeTipColor: #a3a5a6,
    customerIncomeIconDisplay: none,
    customerIncomeMarginLeft: 15px,
    customerIncomeMaxWidth: 100%,
    customerIncomeFontWeight: 14px,

    introductionFontColorStep: #ffffff,
    introductionHeaderFontSize: 30px,
    introductionHeaderMarginBottom: 64px,
    introductionFontWeight: 400,
    introductionFontSize: 14px,
    introductionNumberDisplay: flex,
    introductionNumberWidth: 45px,
    introductionNumberHeight: 45px,
    introductionNumberBackgroundColor: none,
    introductionNumberBorderRadius: 50%,
    introductionTextMaxWidth: 215px,

    formBorder: none,
    tabBorderRight: none,
    tabBorderBottom: none,
    tabBackgroundColor: #30363a,
    tabActiveBackgroundColor: #ffffff,
    tabHeight: 60px,
    tabMargin: 0 0 5px 0,
    tabTextColor: #a3a5a6,
    tabActiveTextColor: rgb(14, 18, 20),
    popupBg: #181b1d,
    popupBorder: 1px solid #c78e66,
    popupTextColor: #ffffff,
  ),
);

@mixin themify($themes) {
  @each $theme, $map in $themes {
    .theme-#{$theme} & {
      $theme-map: () !global;
      @each $key, $submap in $map {
        $value: map-get(map-get($themes, $theme), "#{$key}");
        $theme-map: map-merge(
          $theme-map,
          (
            $key: $value,
          )
        ) !global;
      }
      @content;
      $theme-map: null !global;
    }
  }
}
@mixin themifySliderBg($themes) {
  @each $theme, $map in $themes {
    .theme-#{$theme} & {
      $theme-map: () !global;
      @each $key, $submap in $map {
        $value: map-get(map-get($themes, $theme), "#{$key}");
        $theme-map: map-merge(
          $theme-map,
          (
            $key: $value,
          )
        ) !global;
      }
      @content;
      $theme-map: null !global;
    }
  }
}

@function themed($key) {
  @return map-get($theme-map, $key);
}
