.auto-driver-details {
  margin-top: 30px;
  &__data {
    text-align: left;
    p {
      margin-bottom: 15px;
      line-height: 18px;
    }
  }
  &__subtitle {
    font-weight: bold;
  }
  &__toggle {
    margin-top: 15px;
    color: #808080;
    text-decoration: underline;
    cursor: pointer;
  }
}
