@import "styles/_vars.scss";
@import "styles/_mixins.scss";
@import 'styles/themes.scss';

.info-popup {
  padding: 45px 40px;

  @include respond(tablet-portrait) {
    padding: 38px 25px;
  }

  @include themify($themes) {
    background-color: themed('popupBg');
    color: themed('popupTextColor');
    border: themed('popupBorder');
  }
}
