@import 'styles/_vars.scss';
@import 'styles/_mixins.scss';
@import 'styles/themes.scss';

.footer {
	display: flex;
	justify-content: center;

	&__content {
		display: flex;
		align-items: flex-end;
		max-width: 1024px;
		flex: 1;

		@include respond(mobile-landscape) {
			align-items: flex-start;
      justify-content: space-between;
      padding: 0px 10px;
		}

    @include respond(tablet-landscape) {
      padding: 0px 10px;
    }
	}

	padding: 5px 0 32px 0;

	&__logo {
		width: 64px;
		height: 16px;
		margin-right: 15px;

    path {
      @include themify($themes) {
        fill: themed('colorText');
      }
    }
	}

	&__copyright {
		display: flex;
		align-items: flex-end;
		margin-right: 30px;
	}

	&__protect {
		text-decoration: underline;
		font-size: 10px;

		:not(:last-child) {
			margin-right: 20px;
		}

		@include respond(mobile-landscape) {
			display: flex;
			flex-direction: column;
			justify-content: space-between;

      :not(:last-child) {
        margin-right: 0px;
      }
		}
	}
}
