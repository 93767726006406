.final-conditions-details {
  margin-bottom: 15px;
  &__bank-img {
    margin-top: 15px;
    margin-bottom: 20px;
    width: 120px;
  }
  &__data {
    padding: 15px;
    border-top: 1px solid #B5B5B5;
  }
  &__toggle {
    color: #808080;
    text-decoration: underline;
    cursor: pointer;
  }
  &__line {
    display: flex;
    align-items: flex-end;
    &:not(:last-child) {
      margin-bottom: 14px;
    }
    & > *:first-child {
      margin-bottom: 3px;
      color: #808080;
      font-size: 12px;
      text-align: left;
      span {
        display: inline-block;
        margin-top: 5px;
        margin-right: 5px;
        font-size: 14px;
        color: #000;
      }
    }
    & > *:last-child {
      margin-left: auto;
      text-align: right;
      font-size: 24px;
      font-weight: 600;
      span {
        font-size: 16px;
      }
    }
  }
}
