@import 'styles/_vars.scss';
@import "styles/_mixins.scss";
.container {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 100;
  .close {
    cursor: pointer;
    position: absolute;
    font-size: 22px;
    top: 10px;
    right: 10px; } }

.modal {
  background-color: white;
  z-index: 150;
  padding: 16px;
  box-shadow: $shadow-mild;
  max-height: 92%;
  overflow: auto;
  @include respond(tablet-portrait) {
    box-shadow: none; } }

.content {
  margin-top: 15px;
  @include respond(tablet-portrait) {
    margin-top: 8px; } }
